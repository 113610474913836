import * as actionTypes from "./actionTypes";
import {getData} from "../../shared/utility";

export const getProductsStart = () => {
    return {
        type: actionTypes.GET_PRODUCTS_START
    };
};

export const getProductsSuccess = (products) => {
    return {
        type: actionTypes.GET_PRODUCTS_SUCCESS,
        products: products
    };
};

export const getProductsFail = (error) => {
    return {
        type: actionTypes.GET_PRODUCTS_FAIL,
        error: error
    };
};

export const addProductSkip = (productTag) => {
    return {
        type: actionTypes.ADD_PRODUCT_SKIP,
        tag: productTag
    };
};

export const getProducts = () => {
    return dispatch => {
        dispatch(getProductsStart());
        const userId = localStorage.getItem('userId');
        getData(
            "/user/" + userId + "/products",
            response => {
                dispatch(getProductsSuccess(response.data));
            },
            err => {
                dispatch(getProductsFail(err));
            });
    };
};
