import React from 'react';
import {getProductCounts, orderedProducts, productNames} from "../../../shared/aggregationUtils";
import classes from "../Table.module.css";

const ProductsTable = (props) => {
    const threeDaysFilter = v => {
        return v.pages.includes("Funnel split-3days1495") && !v.pages.includes("Funnel split-7days1495") && !v.pages.includes("Funnel split-1day1495");
    };
    const sevenDaysFilter = v=> {
        return v.pages.includes("Funnel split-7days1495") && !v.pages.includes("Funnel split-3days1495") && !v.pages.includes("Funnel split-1day1495");
    };
    const oneDayFilter = v=> {
        return v.pages.includes("Funnel split-1day1495") && !v.pages.includes("Funnel split-3days1495") && !v.pages.includes("Funnel split-7days1495");
    };

    const productCounts = getProductCounts(props.data);
    const threeDayProductCounts = getProductCounts(props.data, threeDaysFilter);
    const sevenDayProductCounts = getProductCounts(props.data, sevenDaysFilter);
    const oneDayProductCounts = getProductCounts(props.data, oneDayFilter);
    const unknownDayProductCounts = {};
    orderedProducts.forEach(p => {
        const threeDayProductCount = threeDayProductCounts[p] ? threeDayProductCounts[p] : 0;
        const sevenDayProductCount = sevenDayProductCounts[p] ? sevenDayProductCounts[p] : 0;
        const oneDayProductCount = oneDayProductCounts[p] ? oneDayProductCounts[p] : 0;
        unknownDayProductCounts[p] = productCounts[p] - (threeDayProductCount + sevenDayProductCount + oneDayProductCount)
    });
    const productRows = orderedProducts.map(k =>
        <tr className={classes.row} key={k}>
            <th>{productNames[k]}</th>
            <td>{productCounts[k] ? productCounts[k] : 0}</td>
            <td>{threeDayProductCounts[k] ? threeDayProductCounts[k] : 0}</td>
            <td>{sevenDayProductCounts[k] ? sevenDayProductCounts[k] : 0}</td>
            <td>{oneDayProductCounts[k] ? oneDayProductCounts[k] : 0}</td>
            <td>{unknownDayProductCounts[k] ? unknownDayProductCounts[k] : 0}</td>
        </tr>);

    return (
        <table className={classes.table}>
            <tbody>
            <tr className={classes.headerRow2}>
                <th> </th>
                <th>Total Sold</th>
                <th>3 Day Split</th>
                <th>7 Day Split</th>
                <th>1 Day Split</th>
                <th>Unknown</th>
            </tr>
            {productRows}
            </tbody>
        </table>
    );
};

export default ProductsTable;
