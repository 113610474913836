import React, {Component} from 'react';
import classes from './ContactFormPage.module.css';
import image1 from '../../assets/images/newWeekly.jpg';
import image2 from '../../assets/images/newDauily.jpg';
import image3 from '../../assets/images/newNetwork.jpg';
import image4 from '../../assets/images/newQa.jpg';
import image6 from '../../assets/images/newInteractive.jpg';
import image7 from '../../assets/images/newMindset.jpg';
import image5 from '../../assets/images/page2_5.jpg';
import image8 from '../../assets/images/Inner-Game-of-Wealth.jpg';
import ImageBlock from "./ImageBlock";
import ContactFormBlock from "./ContactFormBlock/ContactFormBlock";
import {connect} from "react-redux";
import {FaArrowLeft} from "react-icons/fa";
import * as actions from "../../store/actions";
import Split from "../SplitTest/Split";
import * as splitProfiles from "../../shared/splitProfiles";
import EnrollNow from "../LinkedIn/EnrollNow";

class ContactFormPage extends Component {

    state = {
        expired: false,
        expiredLoaded: false
    };

    componentDidMount() {
        /* const offerExpired = localStorage.getItem("offerExpired");
                const startTime = localStorage.getItem("timer");
                const elapsed = (new Date()).getTime() - Number(startTime) > 30 * 60 * 1000;
                if (offerExpired === 'true' && !elapsed) {
                    this.setState({expired: true, expiredLoaded: true});
                } else if (elapsed) {
                    localStorage.removeItem("timer");
                    localStorage.removeItem("offerExpired");
                    this.setState({expired: false, expiredLoaded: true});
                } else {
                    this.setState({expired: false, expiredLoaded: true});
                }*/
        window.scrollTo(0, 0);
    }


    setExpired() {
        localStorage.setItem("offerExpired", 'true');
        this.setState({expired: true});
    }

    render() {

        return (
            <div className={classes.root}>
                <EnrollNow/>
                <div className={classes.backButtonPlaceholder}/>
                <button className={classes.backButton} onClick={() => this.props.goToHomePage()}>
                    <FaArrowLeft className={classes.backButtonIcon}/>
                    <div className={classes.backButtonText}>Back To Main Page</div>
                </button>
                <div className={classes.backgroundOverlay}/>

                {this.props.contactSuccess ?
                    <h1 className={classes.title}>Double Your Closing Rate In Under 30 Days & <br/> Become A
                        Million-Dollar Producer </h1>
                    :
                    <h1 className={classes.title}>Double Your Closing Rate In Under 30 Days & <br/> Become A
                        Million-Dollar Producer </h1>
                }


                <div className={classes.subRoot}>
                    <div className={classes.block + " " + classes.left}>
                        <h3 className={classes.whatYouGet}>Here's what you get:</h3>
                        <ImageBlock source={image1}/>
                        <ImageBlock source={image2}/>
                        <ImageBlock source={image3}/>
                        <ImageBlock source={image4}/>
                        <ImageBlock source={image6}/>`
                        <ImageBlock source={image7}/>
                        <h3 className={classes.bonusesTitle}>FREE BONUSES If You Join Today:</h3>
                        <div className={classes.bonusesRow}>
                            <ImageBlock source={image5}/>
                            <ImageBlock source={image8}/>
                        </div>
                    </div>
                    <div className={classes.block + " " + classes.right}>
                        <h2 className={classes.rightTitle}>Claim Your 70% Discount On Membership & Every Product In Our
                            Online Store</h2>
                        <Split profile={splitProfiles.threeVsSeven}>
                            <ContactFormBlock/>
                        </Split>
                    </div>
                </div>
                {/* {
                    this.props.contactSuccess && !this.state.expired && this.state.expiredLoaded ?
                        <Aux>
                            <div className={classes.timerBox}>
                                <div>Offer Expires In</div>
                                <Timer onTimeUp={() => {
                                    // this.setExpired();
                                    // window.location.href = "https://jordanbelfort.com/store-description/";
                                }}/>
                            </div>
                            <div className={classes.timerPlaceholder}/>
                        </Aux> :
                        null
                }
                {
                    this.state.expired && this.state.expiredLoaded ? <OfferExpired/> : null
                }*/}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        contactSuccess: state.pages.contactDone
    };
};

const mapDispatchToProps = dispatch => {
    return {
        goToHomePage: () => dispatch(actions.goToHomePage())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactFormPage);
