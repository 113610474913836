import React, {Component} from 'react';
import classes from './Slp.module.css';
import {FaCheck} from "react-icons/fa";
import * as actions from "../../store/actions";
import {connect} from "react-redux";
import * as productTags from '../../shared/products';
import {STRAIGHT_LINE_PERSUASION, STRAIGHT_LINE_PERSUASION_SUB} from '../../shared/products';
import PageSpinner from "../Spinner/PageSpinner";
import CreditForm from "../ContactFormPage/ContactFormBlock/CreditForm/CreditForm";
import VimeoPlayer from "../Vimeo/VimeoPlayer";
import Aux from "../../hoc/Aux";
import slpBanner from "../../assets/images/slpupsellbanner.jpg";
import _ from "lodash";
import {SKIP_SLP, SLP, SLP_PAGE} from '../../shared/events';
import leftSlp from "../../assets/images/leftSb.jpg";
import rightSb from "../../assets/images/slpnew.jpg";

class Slp extends Component {

    state = {
        brainTreeInstance: null,
        brainTreeReady: false,
        item: "full-price"
    };

    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.sendEvent(SLP_PAGE);
    }

    skip() {
        this.props.skip();
        this.props.sendEvent(SKIP_SLP);
    }

    setBrainTreeInstance(instance) {
        instance.on('paymentMethodRequestable', (event) => {
            this.setState({
                ...this.state,
                brainTreeReady: true
            })
        });
        if (this.props.paymentMethod === "google" || this.props.paymentMethod === "venmo") {
            this.setState({
                ...this.state,
                brainTreeInstance: instance,
                brainTreeReady: true
            })
        } else {
            this.setState({
                ...this.state,
                brainTreeInstance: instance,
                brainTreeReady: instance.isPaymentMethodRequestable()
            })
        }
    }

    setItem(item) {
        this.setState({
            ...this.state,
            item: item
        })
    }

    submit() {
        this.props.sendEvent(SLP);
        if (this.props.paymentMethod === "google" || this.props.paymentMethod === "venmo") {
            if (this.state.item === 'full-price') {
                this.props.buy([STRAIGHT_LINE_PERSUASION], this.props.transactionProperties)
            } else if (this.state.item === '3-payments') {
                this.props.buy([STRAIGHT_LINE_PERSUASION_SUB], this.props.transactionProperties)
            }
        } else {
            if (this.state.brainTreeInstance) {
                this.state.brainTreeInstance.requestPaymentMethod((err, payload) => {
                    if (err) {
                        console.log(err);
                    } else {
                        this.submitNonce(payload.nonce)
                    }
                })
            } else {
                console.warn("Instance not loaded");
            }
        }
    }

    submitNonce(nonce) {
        if (this.state.item === 'full-price') {
            this.props.setNonceAndBuy(nonce, [STRAIGHT_LINE_PERSUASION], this.props.transactionProperties)
        } else if (this.state.item === '3-payments') {
            this.props.setNonceAndBuy(nonce, [STRAIGHT_LINE_PERSUASION_SUB], this.props.transactionProperties)
        }
    }

    render() {
        const buttons = <Aux>
            <button className={classes.button} disabled={!this.state.brainTreeReady}
                    onClick={_.debounce(() => this.submit(), 300, {
                        'leading': true,
                        'trailing': false
                    })}>
                <b>Yes</b>, I want this!
            </button>
            <button className={classes.skipButton} onClick={() => this.skip()}>
                <b>No</b>, I don't want this.
            </button>
        </Aux>;

        return (
            <div className={classes.root}>

                <div className={classes.blockAll}>
                    <div className={classes.imageBoxVert}>
                        <img className={classes.images} height={600} src={leftSlp} alt='script'/>
                    </div>


                    {this.props.loading ? <PageSpinner/> : null}
                    {this.props.error ? <div className={classes.error}>{this.props.error}</div> : null}
                    {/*    <p className={classes.notice}>Congratulations, your online membership is confirmed and is being
                    created.</p>*/}


                    <div className={classes.bannerAndVideo}>


                    <div className={classes.checksBlock}>

                        <div className={classes.bannerBox}>
                            <img className={classes.banner + " " + classes.desktopBanner} src={slpBanner}
                                 alt="Save 400 dollars"/>
                        </div>


                        {/*<div className={classes.blockAll}>
                       <div className={classes.imageBoxVert}>
                            <img className={classes.images} height={600} src={rightSb} alt='script'/>
                        </div>*/}


                        <div className={classes.checksSubBlock}>


                            <h1 className={classes.title}>The Course That Transformed 13,000 Struggling Salesmen Into
                                Million-Dollar Producers</h1>
                            <VimeoPlayer
                                width={775}
                                height={436}
                                threshold={1100}
                                multiplier={0.85}
                                playing
                                url="https://vimeo.com/386346889"
                            />
                            <div className={classes.creditBlock}>
                                <CreditForm
                                    brainTreeInstance={this.state.brainTreeInstance}
                                    setBrainTreeInstance={(instance) => this.setBrainTreeInstance(instance)}
                                    price={497}
                                    product="Straight Line Persuasion"
                                />
                            </div>
                            <select className={classes.select + " " + classes.desktop} value={this.state.item}
                                    onChange={(event) => this.setItem(event.target.value)}>
                                <option value="full-price">One Payment of $497</option>
                                <option value="3-payments">Three Payments of only $333</option>
                            </select>
                            <div className={classes.slpButtonWrapper}>
                                {buttons}
                            </div>
                            <h2 className={classes.checksTitle}>The Course Taught In The Movie "The Wolf of Wall
                                Street"</h2>
                            <div className={classes.checksWrapper}>
                                <ul className={classes.checks}>
                                    <li className={classes.check}>
                                        <FaCheck className={classes.checkIcon}/><p>The FOUR core elements of the inner
                                        game
                                        of sales. (These four elements
                                        are
                                        absolutely essential to your success. If you lack even one of them, you will end
                                        up
                                        actually
                                        sabotaging your own success!)</p>
                                    </li>
                                    <li className={classes.check}>
                                        <FaCheck className={classes.checkIcon}/><p>The secret to closing calmly and
                                        consistently every single time without
                                        even
                                        the slightest bit of high pressure. (It’s elegant, it’s classy, and it’s
                                        ridiculously
                                        effective.)</p>
                                    </li>
                                    <li className={classes.check}>
                                        <FaCheck className={classes.checkIcon}/><p>The art and science of Straight Line
                                        prospecting. (You’ll learn how to
                                        develop
                                        a multimillion-dollar sales pipeline free of tire-kickers, so never waste time
                                        again.)</p>
                                    </li>
                                    <li className={classes.check}>
                                        <FaCheck className={classes.checkIcon}/><p>The four key elements to creating
                                        ethical
                                        presentations that actually
                                        close
                                        the
                                        deal. (To be blunt, most sales presentations are totally misguided and actually
                                        stop
                                        you
                                        from closing the deal.)</p>
                                    </li>
                                    <li className={classes.check}>
                                        <FaCheck className={classes.checkIcon}/><p>The step-by-step process of Straight
                                        Line
                                        Negotiating, including the
                                        seven
                                        magic words that allow you to get the lowest price every time)</p>
                                    </li>
                                </ul>
                                <ul className={classes.checks}>
                                    <li className={classes.check}>
                                        <FaCheck className={classes.checkIcon}/><p>How to get into instant rapport with
                                        your
                                        prospects and how to use that
                                        rapport
                                        to gather massive intelligence. (Your prospects will be predisposed to trusting
                                        you,
                                        so
                                        they’ll tell you what their highest values are and where their pain lies.)</p>
                                    </li>
                                    <li className={classes.check}>
                                        <FaCheck className={classes.checkIcon}/><p>The ten-step formula for building a
                                        never-ending stream of customer
                                        referrals
                                        and creating customers for life. </p>
                                    </li>
                                    <li className={classes.check}>
                                        <FaCheck className={classes.checkIcon}/><p>How to raise money through Venture
                                        Capital and Angel Investors, so you
                                        can
                                        start using Other People’s Money to fund your ideas and grow your business</p>
                                    </li>
                                    <li className={classes.check}>
                                        <FaCheck className={classes.checkIcon}/><p>How to instantly squash objections
                                        and
                                        limiting buying beliefs, turning
                                        skeptics into buyers. (This proprietary belief-busting technique is the secret
                                        sauce
                                        of
                                        the
                                        Straight Line System, and will empower your clients to make positive buying
                                        decisions)</p>
                                    </li>
                                    <li className={classes.check}>
                                        <FaCheck className={classes.checkIcon}/><p>And, again, this is just the
                                        beginning.
                                        Once you’ve been through
                                        Jordan’s
                                        Straight Line Persuasion training, you will be literally unstoppable!</p>
                                    </li>
                                </ul>
                            </div>


                        </div>
                        {/*  <div className={classes.imageBoxVert}>
                            <img className={classes.images} height={600} src={rightSb} alt='script'/>
                        </div>
                    </div>*/}


                    </div>
                    </div>




                    <div className={classes.floating}>
                        <select className={classes.select} value={this.state.item}
                                onChange={(event) => this.setItem(event.target.value)}>
                            <option value="full-price">One Payment of $497</option>
                            <option value="3-payments">Three Payments of only $333</option>
                        </select>
                        <div className={classes.buttonRow}>
                            {buttons}
                        </div>
                    </div>
                    <div className={classes.buttonPlaceholder}/>
                    <div className={classes.imageBoxVert}>
                        <img className={classes.images} height={600} src={rightSb} alt='script'/>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state.braintree.loading,
        error: state.braintree.error,
        transactionProperties: state.mixPanel.superProps,
        paymentMethod: state.braintree.paymentMethod

    };
};

const mapDispatchToProps = dispatch => {
    return {
        skip: () => dispatch(actions.addProductSkip(productTags.STRAIGHT_LINE_PERSUASION)),
        sendEvent: (eventTag) => {
            dispatch(actions.sendAnalytics(eventTag));
            dispatch(actions.sendEvent(eventTag))
        },
        setNonceAndBuy: (nonce, products, transactionProperties) => dispatch(actions.setNonceAndBuy(nonce, products, transactionProperties)),
        buy: (products, transactionProperties) => dispatch(actions.buyProducts(products, transactionProperties))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Slp);
