import React from 'react';
import classes from "../Analytics.module.css";

const Tabs = (props) => {
    return (
        <div className={classes.tabs}>
            <button className={classes.tab + (props.activeTab === "overview" ? " " + classes.activeTab : "")} onClick={() => props.setActiveTab("overview")}>Overview</button>
            <button className={classes.tab + (props.activeTab === "events-overview" ? " " + classes.activeTab : "")} onClick={() => props.setActiveTab("events-overview")}>Events</button>
            <button className={classes.tab + (props.activeTab === "events" ? " " + classes.activeTab : "")} onClick={() => props.setActiveTab("events")}>Drilldown</button>
            <button className={classes.tab + (props.activeTab === "removed-rows" ? " " + classes.activeTab : "")} onClick={() => props.setActiveTab("removed-rows")}>Removed Rows</button>
        </div>
    );
};

export default Tabs;