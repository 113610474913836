import * as actionTypes from "./actionTypes";
import {appendLeadPixel, postData} from "../../shared/utility";
import * as splitProfiles from "../../shared/splitProfiles";

export const setContactInfoStart = (contactInfo) => {
    return {
        type: actionTypes.SET_CONTACT_INFO_START,
        info: contactInfo
    }
};

export const setContactInfoSuccess = () => {
    return {
        type: actionTypes.SET_CONTACT_INFO_SUCCESS
    }
};

export const setContactInfoFail = (error) => {
    return {
        type: actionTypes.SET_CONTACT_INFO_FAIL,
        error: error
    }
};

export const setContactSource = (source) => {
    return {
        type: actionTypes.SET_CONTACT_SOURCE,
        source: source
    }
};

export const setContactInfo = (contactInfo) => {
    return dispatch => {
        dispatch(setContactInfoStart(contactInfo));

        localStorage.setItem("email", contactInfo.email);
        let papId = localStorage.getItem("PAPVisitorId");
        let maId = localStorage.getItem("maId");
        let maClickId = localStorage.getItem("maClickId");
        let ip = localStorage.getItem("ip");

        let data;
        if (process.env.NODE_ENV !== 'production') {
            data = {
                firstName: "test",
                lastName: "test",
                email: (Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15))+"@gmail.com",
                phone: 33,
                source: "test funnel",
                papId: papId,
                maId: maId,
                maClickId: maClickId,
                allowExists: contactInfo.allowExists,
                ip: ip,
            };

        } else {
          data = {
                firstName: contactInfo.firstName,
                lastName: contactInfo.lastName,
                email: contactInfo.email,
                phone: contactInfo.phone ? contactInfo.phone : "",
                source: contactInfo.source,
                papId: papId,
                maId: maId,
                maClickId: maClickId,
                allowExists: contactInfo.allowExists,
                ip: ip,
            };
        }


       /* const data = {
            firstName: contactInfo.firstName,
            lastName: contactInfo.lastName,
            email: contactInfo.email,
            phone: contactInfo.phone ? contactInfo.phone : "",
            source: contactInfo.source,
            papId: papId,
            maId: maId,
            maClickId: maClickId,
            allowExists: contactInfo.allowExists,
            ip: ip,
        };*/
        postData(
            "/user",
            data,
            response => {
                const userId = response.data.userId;
                const isNewUser = response.data.newUser;
                localStorage.setItem('userId', userId);
                appendLeadPixel();
                if (isNewUser === true) {
                    setupMixPanelUser(contactInfo);
                } else {
                    if (process.env.NODE_ENV === 'production') {
                        window.mixpanel.identify(contactInfo.email)
                    }
                }
                dispatch(setContactInfoSuccess());
            },
            err => {
                 dispatch(setContactInfoFail(err.response.data.message));
            });
    };
};

const setupMixPanelUser = (contactInfo) => {
    if (process.env.NODE_ENV === 'production') {
        const splitVars = localStorage.getItem('split');
        let trialSplit = "unknown";
        if (splitVars !== null) {
            const parsed = JSON.parse(splitVars);
            const index = parsed[splitProfiles.threeVsSeven.name];
            trialSplit = splitProfiles.threeVsSeven.values[index].name;
        }

        window.mixpanel.alias(contactInfo.email);
        window.mixpanel.people.set({
            "$email": contactInfo.email,
            "$created": new Date(),
            "$first_name": contactInfo.firstName,
            "$last_name": contactInfo.lastName,
            "$phone": contactInfo.phone,
            "Trial Days Split": trialSplit,
            "Lifetime Spend": 0
        });
    }
};
