import React, {Component} from 'react';
import classes from "../Landing.module.css";
import VimeoPlayer from "../../Vimeo/VimeoPlayer";

class VideoBlock extends Component {

    render() {
        const hasTrial = this.props.split.days > 0;
        const buttonTitle = hasTrial ? 'Join Right Now' : 'Enroll Now';
        if (!this.props.split) {
            return null
        }
        return (
            <div className={classes.mainBg}>
                <div className={classes.videoBackground}>
                    <div className={classes.titleRow}>
                        <div className={classes.titleText}>
                            <span>
                                {this.props.split.landingTitle}
                                <span className={classes.discountAmount}> {this.props.split.landingAmount}</span>
                                {this.props.split.landingTitleBottom}

                            </span><br/>
                            {this.props.split.secondTitle}<br/>
                            {this.props.split.thirdTitle}
                            <div className={classes.subHeader}>
                                {this.props.split.subHeader}
                            </div>
                        </div>
                    </div>
                    <div className={classes.learnMore}>See How the Program Works:</div>
                    <div className={classes.videoWrapper}>
                        <VimeoPlayer
                            playing
                            preload
                            width={800}
                            height={446}
                            threshold={1100}
                            multiplier={0.90}
                            url={this.props.split.videoUrl}
                        />
                    </div>
                    <div className={classes.bannerBox}>
                        <div className={classes.yellowBanner}>
                            <div className={classes.bannerText}>
                                Limited-Time Offer: <span>Massive 70% Discount + Free Bonuses</span>

                            </div>
                        </div>
                    </div>
                    <p className={classes.offerWhite}>Sign Up Today And Become a Million Dollar Producer</p>
                    <button className={classes.greenPulse} onClick={() => this.props.buttonClicked()}>
                        <div>{buttonTitle}</div>

                        <div className={classes.buttonSubtext}>Claim Your Free Bonuses + A One-Time 70% Discount On Every Product In Our Online Store</div>

                    </button>
                </div>
            </div>
        );
    }
}

export default VideoBlock;
