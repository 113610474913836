import React, {Component} from 'react';
import classes from './BundleForm.module.css';

class BundleForm extends Component {

    render() {
        return (
            <div className={classes.root}>
                <div className={classes.subRoot}>
                <input className={classes.input} placeholder="First Name"/>
                <input className={classes.input} placeholder="Last Name"/>
                <input className={classes.input} placeholder="Company Name"/>
                <input className={classes.input} placeholder="Contact Number"/>
                <input className={classes.input} placeholder="Email Address"/>
                <select className={classes.input}>
                    <option>5</option>
                    <option>10</option>
                    <option>20</option>
                    <option>Enterprise</option>
                </select>
                </div>
            </div>
        );
    }
}

export default BundleForm;
