import React, {Component} from 'react';
import classes from './Increase.module.css';
import box from '../../../assets/images/dmbox200.jpg'
import box2 from '../../../assets/images/weeklyintensivebox.jpg'
import box3 from '../../../assets/images/qanda.jpg';
import box4 from '../../../assets/images/networking.jpg';
import {FaLockOpen} from "react-icons/fa";

class Increase extends Component {

    render() {
        const hasTrial = this.props.split.days > 0;
        const buttonTitle = hasTrial ? 'Join Right Now' : 'Enroll Now';
        return (
            <div className={classes.root}>
                <div className={classes.container}>

                    <div className={classes.left}>
                        <p className={classes.subText}>
                            JOIN TODAY & GET <b>INSTANT ACCESS</b> TO THE ENTIRE LIBRARY OF JORDAN'S PAST SALES TRAININGS
                            & DAILY MOTIVATIONS ...

                        </p>
{/*
                        <p className={classes.subTextTwo}>& DAILY MOTIVATIONS ...</p>
*/}
                        <div className={classes.imageContainer}>
                            <div className={classes.imageBox}>
                                <img className={classes.image} src={box} alt="Training"/>
                            </div>
                            <div className={classes.imageBox}>
                                <img className={classes.image} src={box2} alt="Training"/>
                            </div>
                            <div className={classes.imageBox}>
                                <img className={classes.image} src={box3} alt="Training"/>
                            </div>
                            <div className={classes.imageBox}>
                                <img className={classes.image} src={box4} alt="Training"/>
                            </div>

                        </div>
                        {/*<div className={classes.imageContainer}>
                            <div className={classes.imageBox}>
                                <img className={classes.image} src={box} alt="Training"/>
                            </div>
                            <div className={classes.imageBox}>
                                <img className={classes.image} src={box2} alt="Training"/>
                            </div>
                        </div>*/}
                    </div>
                    <div className={classes.mobileBlock}>
                        <div className={classes.mobileBanner}>500+ Videos Combined</div>
                        <p className={classes.mobileInfo}>Designed to work hand-in-hand with each other, the <b>Weekly
                            Intensives</b> focus on the practical application of the Straight Line System, while the <b>Daily
                            Motivations</b> ensure that you stay at the top of your sales game every single day.</p>
                    </div>
                    <div className={classes.right}>
                        <div className={classes.headingText}>MASSIVELY INCREASE:</div>
                        <li className={classes.checks}>
                            <div className={classes.check}>1</div>
                            <p>Your average <b>daily closing rate</b> (expect a significant bump, in the first 2 weeks
                                alone)</p>
                        </li>
                        <li className={classes.checks}>
                            <div className={classes.check}>2</div>
                            <p>Your average <b>sale-size</b> (right out the gate)</p>
                        </li>
                        <li className={classes.checks}>
                            <div className={classes.check}>3</div>
                            <p>The average number of <b>referrals</b> you get (you'll create a steady stream of them)
                            </p>
                        </li>
                        <li className={classes.checks}>
                            <div className={classes.check}>4</div>
                            <p>Your average monthly <b>commission check</b> (you'll become a top producer in your
                                industry)</p>
                        </li>
                        <button className={classes.ctaButton} onClick={() => this.props.buttonClicked()}>
                            <FaLockOpen/>
                            {buttonTitle}
                            <span>Claim Your Free Bonuses + A One-Time 70% Discount On Every Product In Our Online Store</span>
                        </button>
                    </div>
                </div>

            </div>
        )

    }
}

export default Increase;
