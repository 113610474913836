import React, {Component} from 'react';
import classes from './Bundles.module.css';
import BundleFeatures from "./Features/BundleFeatures";
import BundlePricing from "./Pricing/BundlePricing";
import BundleForm from "./Form/BundleForm";

class Bundles extends Component {

    state = {
        currentPage: "features"
    };

    changePage(page) {
        this.setState({
            ...this.state,
            currentPage: page
        })
    }

    render() {

        let page = null;

        if (this.state.currentPage === "features") {
            page = <BundleFeatures/>
        } else if (this.state.currentPage === "pricing") {
            page = <BundlePricing/>
        } else if (this.state.currentPage === "form") {
            page = <BundleForm/>
        }

        return (
            <div className={classes.root}>
                <div className={classes.header}>
                    <button className={classes.headerButton} onClick={() => this.changePage("features")}>Features</button>
                    <button className={classes.headerButton} onClick={() => this.changePage("pricing")}>Pricing</button>
                    <button className={classes.getItButton} onClick={() => this.changePage("form")}>Get it now!</button>
                </div>
                { page }
            </div>
        );
    }
}

export default Bundles;
