import React from 'react';
import './HamburgerMenu.css';
import {FaBars, FaTimes} from 'react-icons/fa';
import {CSSTransition} from "react-transition-group";

class HamburgerMenu extends React.Component {
    state = {
        isOpen: false
    };

    toggleMenu() {
        this.setState({isOpen: !this.state.isOpen});
    }

    render() {
        return (
            <div className="hamburgerMenu">
                <button
                    onClick={() => this.toggleMenu()}
                    className="toggle-btn"
                >
                    {this.state.isOpen ? < FaTimes size="2em"/> : <FaBars size="2em"/>}
                </button>
                <CSSTransition
                    in={this.state.isOpen}
                    appear={true}
                    unmountOnExit
                    classNames="slide"
                    timeout={400}
                >
                    <div className="content">
                        {this.props.links}
                    </div>
                </CSSTransition>
            </div>
        );
    }
}

export default HamburgerMenu;
