import * as actionTypes from "./actionTypes";
import {getData, postData} from "../../shared/utility";
import {firePixel} from "./state";
import {FAST_TRACK, subscriptionProducts} from "../../shared/products";
import {buyOrderFormFail, buyOrderFormStart, buyOrderFormSuccess} from "./orderForm";


export const getBrainTreeTokenStart = () => {
    return {
        type: actionTypes.GET_BRAIN_TREE_TOKEN_START,
    }
};

export const getBrainTreeTokenSuccess = (token) => {
    return {
        type: actionTypes.GET_BRAIN_TREE_TOKEN_SUCCESS,
        token: token
    }
};

export const getBrainTreeTokenFail = (error) => {
    return {
        type: actionTypes.GET_BRAIN_TREE_TOKEN_FAIL,
        error: error
    }
};

export const setBrainTreeNonceStart = () => {
    return {
        type: actionTypes.SET_BRAIN_TREE_NONCE_START,
    }
};

export const setBrainTreeNonceSuccess = () => {
    return {
        type: actionTypes.SET_BRAIN_TREE_NONCE_SUCCESS,
    }
};

export const setBrainTreeNonceFail = (error) => {
    return {
        type: actionTypes.SET_BRAIN_TREE_NONCE_FAIL,
        error: error
    }
};

export const buyProductsStart = () => {
    return {
        type: actionTypes.BUY_BRAIN_TREE_PRODUCTS_START,
    }
};

export const buyProductsSuccess = (products) => {
    return {
        type: actionTypes.BUY_BRAIN_TREE_PRODUCTS_SUCCESS,
        products: products
    }
};

export const buyProductsFail = (error) => {
    return {
        type: actionTypes.BUY_BRAIN_TREE_PRODUCTS_FAIL,
        error: error
    }
};

export const invalidateBrainTreeToken = () => {
    return {
        type: actionTypes.INVALIDATE_BRAIN_TREE_TOKEN
    }
};

export const getBrainTreeToken = () => {
    return dispatch => {
        dispatch(getBrainTreeTokenStart());
        const userId = localStorage.getItem("userId");
        const subUrl = "/braintree/token/" + userId;

        getData(
            subUrl,
            response => {
                dispatch(getBrainTreeTokenSuccess(response.data));
            },
            err => {
                console.log(err);
                dispatch(getBrainTreeTokenFail(err.response.data.message));
            });
    };
};

export const paymentMethodUsed = (description) => {
    let paymentMethod;
    if (description === 'AndroidPayCard') {
        paymentMethod = 'google';
    }

    if (description === "VenmoAccount") {
        paymentMethod = 'venmo';
    }
    return {
        type: actionTypes.PAYMENT_METHOD_USED,
        paymentMethod
    }
};

export const setNonceAndBuy = (nonce, products, transactionProperties, trackPixel) => {
    return dispatch => {
        dispatch(setBrainTreeNonceStart());
        const userId = localStorage.getItem("userId");

        let subUrl = "/braintree/nonce/" + userId + "/" + nonce;

        postData(
            subUrl,
            {},
            response => {
                dispatch(setBrainTreeNonceSuccess());
                dispatch(buyProducts(products, transactionProperties, trackPixel))
            },
            err => {
                dispatch(setBrainTreeNonceFail(err.response.data.message));
            });
    };
};

export const setNonceAndBuyFromForm = (nonce, formId) => {
    return dispatch => {
        dispatch(setBrainTreeNonceStart());
        const userId = localStorage.getItem("userId");

        let subUrl = "/braintree/nonce/" + userId + "/" + nonce;

        postData(
            subUrl,
            {},
            response => {
                dispatch(setBrainTreeNonceSuccess());
                dispatch(buyProductFromForm(formId))
            },
            err => {
                dispatch(setBrainTreeNonceFail(err.response.data.message));
            });
    };
};

export const buyProductFromForm = (formId) => {
    return dispatch => {
        dispatch(buyOrderFormStart());
        const subUrl = "/braintree/form/" + formId;

        postData(
            subUrl,
            {},
            response => {
                dispatch(buyOrderFormSuccess());
            },
            err => {
                if (err.response) {
                    dispatch(buyOrderFormFail(err.response.data.message));
                } else {
                    dispatch(buyOrderFormFail("Something unexpected went wrong"));
                }
            });
    };
};

export const buyProducts = (products, transactionProperties, trackPixel) => {
    return dispatch => {
        dispatch(buyProductsStart());
        const userId = localStorage.getItem("userId");
        const Source = localStorage.getItem('source');
        const subUrl = "/braintree/buy/" + userId;

        postData( 
            subUrl,
            {
                products: products,
                transactionProperties: transactionProperties,
                mixpanelProperties: {
                    Source,
                    Funnel: 'The Wolf Network (70% Discount) Snapchat Funnel',
                    purchasedFrom: document.URL,
                },
                funnel: "The Wolf Network (70% Discount) Snapchat Funnel"
            },
            response => {
                if (response.data.hasFailure && response.data.failed.some(tag => subscriptionProducts.includes(tag))) {
                    let message = "Failed to setup your subscription, please try again.";
                    if (response.data.successful.includes(FAST_TRACK)) {
                        message = "Failed to setup your subscription, but your Fast-Track purchase has succeeded, please try again without selecting the Fast-Track option."
                    }
                    dispatch(buyProductsFail(message));
                    return;
                }
                if (response.data.successful.length === 0) {
                    dispatch(buyProductsFail("Failed to buy product"));
                    return;
                }
                /* if (response.data.successful.some(tag => subscriptionProducts.includes(tag))) {
                     appendConversionPixel(localStorage.getItem('email'))
                 }*/
                dispatch(buyProductsSuccess(response.data.successful));
                if (trackPixel) {
                    dispatch(firePixel(response.data.successful))
                }
                /*
                                dispatch(firePixel(response.data.successful))
                */
            },
            err => {
                if (err.response) {
                    dispatch(buyProductsFail(err.response.data.message));
                } else {
                    dispatch(reCheckProduct(products));
                }
            });
    };
};

export const reCheckProduct = (products) => {
    return dispatch => {
        getData(
            "/user/" + localStorage.getItem("userId") + "/products",
            response => {
                if (products.every(p => response.data.map(v => v.tag).includes(p))) {
                    console.log("recheck");
                    dispatch(buyProductsSuccess(products));
                    dispatch(firePixel(products));
                } else {
                    dispatch(buyProductsFail("Something unexpected went wrong."));
                }
            },
            err => {
                console.log("recheck fail");
                if (err.response) {
                    dispatch(buyProductsFail(err.response.data.message));
                } else {
                    dispatch(buyProductsFail("Something unexpected went wrong."));
                }
            }
        )
    }
};
