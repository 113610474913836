import React, {Component} from 'react';
import classes from './Landing.module.css';
import Increase from "./Increase/Increase";
import Effective from "./Effective/Effective";
import {connect} from "react-redux";
import * as actions from "../../store/actions";
import Leo from "./Leo/Leo";
import About from "./About/About";
import Footer from "./Footer/Footer";
import * as queryString from "query-string";
import Split from "../SplitTest/Split";
import * as splitProfiles from '../../shared/splitProfiles';
import VideoBlock from "./Video/VideoBlock";
import Aux from "../../hoc/Aux";
import {LANDING_PAGE} from "../../shared/events";
import FloatingButton from "./FloatingButton/FloatingButton";
import ReactPixel from "react-facebook-pixel";
import StrategySession from "./StrategySession/StrategySession";
import Header from "../Header/ShopJbHeader/Header";
import HeaderDesktop from "../Header/Header";
import {browserName, isMobile, isTablet, mobileModel, osName} from "react-device-detect";


class Landing extends Component {

    componentDidMount() {
        const browser = browserName;
        const operatingSystem = osName;
        let device;
        let deviceCategory;

        if (isMobile){
            device=mobileModel;
            deviceCategory="Mobile"
        } else if (isTablet){
            device=mobileModel;
            deviceCategory = "Tablet";
        }
        else if (!isMobile && !isTablet) {
            device=osName;
            deviceCategory="Desktop"
        }
        localStorage.setItem("browser", browser);
        localStorage.setItem("operatingSystem", operatingSystem);
        localStorage.setItem("device", device);
        localStorage.setItem("deviceCategory", deviceCategory);


        let query = this.props.location.search;
        const values = queryString.parse(query);
        // let src = "Organic";
        // if (values.fbclid){
        //     src = "Facebook Ads"
        // }
        // if (values.src) {
        //     src = values.src
        // }
        if (values.IkD8D1) {
            localStorage.setItem("smsId", values.IkD8D1)
        }
        if (values.sub) {
            localStorage.setItem("maId", values.sub);
            this.props.setAffiliateProps("Monster Ads", values.sub);
        }
        if (values.sub2) {
            localStorage.setItem("maClickId", values.sub2)
        }

        setTimeout(() => this.props.sendEvent(LANDING_PAGE), 1000);

        const options = {
            autoConfig: true, 	// set pixel's autoConfig
            debug: false, 		// enable logs
        };

        ReactPixel.init('666573736733134', null, options);
        ReactPixel.init('1185032095006553', null, options);
        ReactPixel.pageView();
        if (values.rdr && values.rdr === "form") {
            this.props.joinClicked()
        }
    }

    findSource(urlParams) {
        const sourceText = urlParams.get('utm_source');
        if (!sourceText) return 'Organic';
        const sourceSplit = sourceText.split('_');

        if (sourceSplit.length === 0 && sourceText) {
            console.log("utm provided, not split: ", sourceSplit, sourceText)
        }

        if (sourceText[0] === 'D') {
            if (sourceText[2] === 'l') {
                return 'LinkedIn';
            }
            return 'Youtube';
        } else if (sourceSplit.length > 1) {
            return sourceSplit[0];
        } else {
            return 'Organic';
        }
    };


    render() {
        return (
            <Aux>

                <div className={classes.desktopHeader}>
                    <HeaderDesktop/>
                </div>
                <div className={classes.mobileHeader}>
                    <Header/>
                </div>

                <div className={classes.root}>
                    <Split profile={splitProfiles.landingPageVideo} additionalProfiles={[splitProfiles.threeVsSeven]}>
                        <VideoBlock buttonClicked={this.props.joinClicked}/>
                    </Split>
                    <Split profile={splitProfiles.threeVsSeven}>
                        <Increase buttonClicked={this.props.joinClicked}/>
                    </Split>
                    <Split profile={splitProfiles.threeVsSeven}>
                        <StrategySession buttonClicked={this.props.joinClicked}/>
                    </Split>
                    <Split profile={splitProfiles.threeVsSeven}>
                        <Effective/>
                    </Split>
                    <Split profile={splitProfiles.threeVsSeven}>
                        <Leo buttonClicked={this.props.joinClicked}/>
                    </Split>
                    <Split profile={splitProfiles.threeVsSeven}>
                        <About buttonClicked={this.props.joinClicked}/>
                    </Split>
                    <Footer/>
                    <Split profile={splitProfiles.threeVsSeven}>
                        <FloatingButton buttonClicked={this.props.joinClicked}/>
                    </Split>
                </div>
            </Aux>
        )
    }
}

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = dispatch => {
    return {
        joinClicked: () => {
            ReactPixel.trackCustom('EnrollNow', {content_name: 'wolfnetwork70off'});
            dispatch(actions.joinButtonClicked())
        },
        addSource: (source) => dispatch(actions.setContactSource(source)),
        sendEvent: (eventTag) => {
            dispatch(actions.sendAnalytics(eventTag));
            dispatch(actions.sendEvent(eventTag))
        },
        setAffiliateProps: (system, id) => dispatch(actions.setAffiliateProps(system, id))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Landing);
