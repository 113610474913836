import React from 'react';
import classes from "../Analytics.module.css";

const GetControls = (props) => {
    return (
        <div className={classes.dateContainer}>
            <input type="date" onChange={event => props.handleStartDateChange(event)}
                   value={props.startDate}/>
            <input type="date" onChange={event => props.handleEndDateChange(event)}
                   value={props.endDate}/>
            <button className={classes.getButton} onClick={() => props.handleSubmit()}>Refresh</button>
        </div>
    );
};

export default GetControls;