import React, {Component} from 'react';
import ContactForm from "../ContactFormPage/ContactFormBlock/ContactForm/ContactForm";
import classes from "./DirectSlp.module.css";
import {connect} from "react-redux";
import {STRAIGHT_LINE_PERSUASION} from "../../shared/products";
import _ from "lodash";
import CreditForm from "../ContactFormPage/ContactFormBlock/CreditForm/CreditForm";
import * as actions from "../../store/actions";
import PageSpinner from "../Spinner/PageSpinner";
import {SLP} from '../../shared/events';

class DirectSlp extends Component {

    state = {
        brainTreeInstance: null,
        brainTreeReady: false
    };

    componentDidMount() {
        this.props.getProducts();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!prevProps.contactSuccess && this.props.contactSuccess) {
            this.props.getProducts();
        }
        if (prevProps.loading && !this.props.loading) {
            this.props.getProducts();
        }
    }

    setBrainTreeInstance(instance) {
        instance.on('paymentMethodRequestable', (event) => {
            this.setState({
                ...this.state,
                brainTreeReady: true
            })
        });
        this.setState({
            ...this.state,
            brainTreeInstance: instance,
            brainTreeReady: instance.isPaymentMethodRequestable()
        })
    }

    buy() {
        this.props.sendEvent(SLP);
        if (this.state.brainTreeInstance) {
            this.state.brainTreeInstance.requestPaymentMethod((err, payload) => {
                if (err) {
                    // Do something with the error
                } else {
                    this.submitNonce(payload.nonce)
                }
            })
        } else {
            console.warn("Instance not loaded");
        }
    }

    submitNonce(nonce) {
        this.props.setNonceAndBuy(nonce, [STRAIGHT_LINE_PERSUASION], this.props.transactionProperties)
    }

    render() {
        if (this.props.products.map(p => p.tag).includes(STRAIGHT_LINE_PERSUASION)) {
            return (
                <div className={classes.root}>
                    <h1>Thank you for your purchase!</h1>
                </div>
            )
        }
        return (
            <div className={classes.root}>
                {this.props.loading ? <PageSpinner/> : null}
                {this.props.error ? <div className={classes.error}>{this.props.error}</div> : null}
                <h1>Straight Line Persuasion for Only £766</h1>
                <h2>50% Off (Normal Price £1533)</h2>
                <div className={classes.subRoot}>
                    {this.props.contactSuccess ?
                        <div>
                            <div className={classes.creditBlock}>
                                <CreditForm
                                    brainTreeInstance={this.state.brainTreeInstance}
                                    setBrainTreeInstance={(instance) => this.setBrainTreeInstance(instance)}
                                    price={997}
                                    product="Straight Line Persuasion"
                                />
                            </div>
                            <button className={classes.buttonYes} disabled={!this.state.brainTreeReady}
                                    onClick={_.debounce(() => this.buy(), 300, {
                                        'leading': true,
                                        'trailing': false
                                    })}>
                                <b>Yes</b>, I want this!
                            </button>
                        </div> :
                        <ContactForm allowExists/>
                    }
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        contactSuccess: state.pages.contactDone,
        slpDone: state.pages.slpDone,
        loading: state.braintree.loading,
        error: state.braintree.error,
        products: state.product.products,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        sendEvent: (eventTag) => {
            dispatch(actions.sendAnalytics(eventTag));
            dispatch(actions.sendEvent(eventTag))
        },
        getProducts: () => dispatch(actions.getProducts()),
        setNonceAndBuy: (nonce, products, transactionProperties) => dispatch(actions.setNonceAndBuy(nonce, products, transactionProperties))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DirectSlp);
