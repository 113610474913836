export const LANDING_PAGE = "Landing Page";
export const CHECKOUT_PAGE = "Checkout Page";
export const OPT_IN = "User Filled in Popup";
export const SUBMIT_CONTACT = "Submit Contact Form";
export const SUBMIT_CREDIT = "Submit Credit Form";
export const MONTHLY = "Monthly";
export const QUARTERLY = "Quarterly";
export const YEARLY = "Yearly";
export const FAST_TRACK = "Fast Track";
export const UPSELL = "Upsell SB";
export const SKIP_UPSELL = "Skip Upsell";
export const UPSELL_PAGE = "Upsell Page";
export const DOWNSELL = "Downsell SB";
export const SKIP_DOWNSELL = "Skip Downsell";
export const DOWNSELL_PAGE = "Downsell Page";
export const SLP = "SLP";
export const SKIP_SLP = "Skip SLP";
export const SLP_PAGE = "SLP Page";
export const MANUAL = "Stratton Oakmont";
export const SKIP_MANUAL = "Skip Manual";
export const MANUAL_PAGE = "Manual Page";
export const THANK_YOU_PAGE = "Thank You Page";

export const SLSC_CERT_FULL = "Straight Line Sales Certification";
export const SLM = "Straight Line Marketing";
export const MANUAL_PRINT = "Stratton Oakmont (Hardcopy)";

export const displayEvents = [
    LANDING_PAGE,
    CHECKOUT_PAGE,
    OPT_IN,
    MONTHLY,
    QUARTERLY,
    YEARLY,
    FAST_TRACK,
    UPSELL,
    DOWNSELL,
    SLP,
    MANUAL
];
