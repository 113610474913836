import * as productTags from "./products";

export const toMoneyString = (value) => {
    return "$" + value.toFixed(2);
};

export const getAverageValuePerClick = (data, dataFilter) => {
    let filtered;
    if (dataFilter) {
        filtered = data.filter(v => dataFilter(v));
    } else {
        filtered = data
    }
    if (filtered.length === 0) {
        return 0;
    }
    return getTotalOrderValue(filtered) / filtered.length
};

export const getAverageOrderValue = (data, dataFilter) => {
    let filtered;
    if (dataFilter) {
        filtered = data.filter(v => dataFilter(v));
    } else {
        filtered = data
    }
    if (filtered.length === 0) {
        return 0;
    }
    let filteredWithProducts = filtered.filter(v => v.products.length > 0);
    if (filteredWithProducts.length === 0) {
        return 0;
    }
    return getTotalOrderValue(filtered) / filteredWithProducts.length
};

export const getTotalOrderValue = (data, dataFilter) => {
    if (dataFilter) {
        return data
            .filter(v => dataFilter(v))
            .reduce((acc, val) => acc + val.products.reduce((pAcc, p) => pAcc + p.price, 0), 0);
    } else {
        return data.reduce((acc, val) => acc + val.products.reduce((pAcc, p) => pAcc + p.price, 0), 0)
    }
};

export const getUniqueCount = (data, dataFilter) => {
    if (dataFilter) {
        return data.filter(val => dataFilter(val)).length;
    } else {
        return data.length;
    }
};

export const getProductCounts = (data, dataFilter) => {
    let filtered;
    if (dataFilter) {
        filtered = data.filter(val => dataFilter(val));
    } else {
        filtered = data;
    }
    let counts = {};
    const flatMap = filtered.flatMap(v => v.products).map(p => p.tag);
    flatMap.forEach(p => {
        counts[p] = counts[p] ? counts[p] + 1 : 1;
    });
    return counts;
};

export const orderedProducts = [
    productTags.SUB_MONTHLY,
    productTags.SUB_QUARTERLY_3_3375,
    productTags.SUB_ANNUAL,
    productTags.SUB_MONTHLY_3_1995,
    productTags.SUB_QUARTERLY_3_4200,
    productTags.SUB_ANNUAL_3_9700,
    productTags.SUB_MONTHLY_7_1495,
    productTags.SUB_QUARTERLY_7_3375,
    productTags.SUB_ANNUAL_7_8900,
    productTags.FAST_TRACK,
    productTags.SCRIPT_BUILDER_UPSELL,
    productTags.SCRIPT_BUILDER_DOWNSELL,
    productTags.STRAIGHT_LINE_PERSUASION,
    productTags.STRAIGHT_LINE_PERSUASION_SUB,
    productTags.SO_MANUAL
];

export const productNames = {
    [productTags.SUB_MONTHLY]: "Monthly 3 days $14.95",
    [productTags.SUB_ANNUAL]: "Yearly 3 days $89.00",
    [productTags.SUB_QUARTERLY_3_3375]: "Quarterly 3 days $33.75",
    [productTags.SUB_MONTHLY_1_1495]: "Monthly 1 days $14.95",
    [productTags.SUB_ANNUAL_1_8900]: "Yearly 1 days $89.00",
    [productTags.SUB_QUARTERLY_1_3375]: "Quarterly 1 days $33.75",
    [productTags.SUB_MONTHLY_3_1995]: "Monthly 3 days $19.95",
    [productTags.SUB_ANNUAL_3_9700]: "Yearly 3 days $97.00",
    [productTags.SUB_QUARTERLY_3_4200]: "Quarterly 3 days $42.00",
    [productTags.SUB_MONTHLY_7_995]: "Monthly 7 days $9.95",
    [productTags.SUB_ANNUAL_7_8900]: "Yearly 7 days $89.00",
    [productTags.SUB_MONTHLY_7_1495]: "Monthly 7 days $14.95",
    [productTags.SUB_QUARTERLY_7_3375]: "Quarterly 7 days $33.75",
    [productTags.FAST_TRACK]: "Fast-Track",
    [productTags.SCRIPT_BUILDER_UPSELL]: "Script-Builder Upsell",
    [productTags.SCRIPT_BUILDER_DOWNSELL]: "Script-Builder Downsell",
    [productTags.STRAIGHT_LINE_PERSUASION]: "Straight-Line Persuasion",
    [productTags.STRAIGHT_LINE_PERSUASION_SUB]: "Straight-Line Persuasion 3 Payments",
    [productTags.SO_MANUAL]: "Stratton-Oakmont Manual"
};