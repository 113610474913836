import React, {Component} from 'react';
import classes from './Effective.module.css';
import {FaChartLine, FaMoneyBillAlt, FaPlayCircle, FaQuestionCircle} from "react-icons/fa";
import Reason from "./Reason";


class Effective extends Component {
    render() {
        return (
            <div className={classes.root}>
                <div className={classes.subRoot}>
                    <h2 className={classes.headerText}>WHAT MAKES THIS TRAINING PROGRAM SO EFFECTIVE?</h2>
                    <ul className={classes.reasons}>
                        <Reason
                            title='Intensive Coaching'
                            body='Each month join the Wolf’s intensive coaching sessions, where you ask questions LIVE on video and converse with Jordan about the Straight Line.'
                        ><FaQuestionCircle className={classes.icon}/></Reason>
                        <Reason
                            title='100% PURE TRAINING, WITH ZERO FLUFF'
                            body='Every minute of every training consists of only that: training. There is no fluff, no fillers, no annoying ads, and you NEVER get sold to at the end. These trainings are powerful; they’re directed; and they’ve been designed to get you both immediate and lasting results.'
                        ><FaChartLine className={classes.icon}/></Reason>
                        <Reason
                            title='ALL VIDEOS ARCHIVED'
                            body='There is no need to worry if you can’t attend a particular training. Once you subscribe, you’ll be granted access to our members area where you can watch all of JB’s past trainings in HD, including the extended Q&A sessions.'
                        ><FaPlayCircle className={classes.icon}/></Reason>
                        <Reason
                            title='AFFORDABILITY'
                            body={'Everyone deserves to reap the massive financial benefits that come from learning the Straight Line System—no matter how challenging you current financial situation is. With a massive 70% discount, not only is this program the deal of the century but it’s also affordable to everyone.'}
                        ><FaMoneyBillAlt className={classes.icon}/></Reason>
                    </ul>
                </div>
            </div>


        )

    }
}

export default Effective;
