import React, {Component} from 'react';
import DropIn from 'braintree-web-drop-in';
import * as actions from "../../../../store/actions";
import {connect} from "react-redux";
import Aux from "../../../../hoc/Aux";

class CreditForm extends Component {

    componentDidMount() {
        if (!this.props.token && !this.props.loading && !this.props.error) {
            this.props.getToken();
        }
    }

    componentWillUnmount() {
        if (this.props.token) {
            this.props.invalidateToken();
        }
        if (this.props.brainTreeInstance) {
            this.props.brainTreeInstance.teardown(function () {
                console.log("teardown done")
            });
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!this.props.token && !this.props.loading && !this.props.error) {
            this.props.getToken();
        }
        if (this.props.token && this.props.brainTreeInstance === null) {
            DropIn.create({
                authorization: this.props.token,
                paymentOptionPriority: ['card', 'paypal', 'googlePay', 'venmo'],
                container: '#dropin-container',
                googlePay: {
                    flow: 'vault',
                    transactionInfo: {
                        currencyCode: "USD",
                        totalPriceStatus: "NOT_CURRENTLY_KNOWN"
                    }
                },
                venmo: true,
                paypal: {
                    flow: 'vault',
                    buttonStyle: {
                        color: 'blue',
                        shape: 'rect',
                        size: 'medium'
                    }
                }
            }).then((instance) => {
                this.props.setBrainTreeInstance(instance);
            });
        }
    }

    render() {
        return (
            <Aux>
                {this.props.error ? <div>{this.props.error}</div> : null}
                <div id="dropin-container"/>
            </Aux>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state.braintree.loading,
        token: state.braintree.token,
        error: state.braintree.error,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getToken: () => dispatch(actions.getBrainTreeToken()),
        invalidateToken: () => dispatch(actions.invalidateBrainTreeToken()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreditForm);
