import React, {Component} from 'react';
import classes from './StrategySession.module.css';
import {FaLockOpen} from "react-icons/fa";


class StrategySession extends Component {

    render() {
        const hasTrial = this.props.split.days > 0;
        const buttonTitle = hasTrial ? 'Join Right Now' : 'Enroll Now';
        return (
            <div className={classes.root}>
                <div className={classes.subRoot}>
                    <div className={classes.title}>Includes an In-Depth Onboarding/Coaching Call:</div>
                    <p className={classes.text}>
                        The moment you join The Wolf Network, one of my hand-picked team members will give you a call to
                        walk you through the entire program, in order to ensure your success.
                    </p>
                    {<p className={classes.text}>
                        This high impact session will  ensure you get off to a flying start with your training and set you up for long-term success.
                    </p>}
                    <button className={classes.button} onClick={() => this.props.buttonClicked()}>
                        <FaLockOpen/>
                        {buttonTitle}
                        <span>Claim Your Free Bonuses + A One-Time 70% Discount On Every Product In Our Online Store</span>
                    </button>
                </div>
            </div>
        )
    }
}

export default StrategySession;
