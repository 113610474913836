import React from 'react';
import Logo from "../../Header/Logo";
import classes from './Footer.module.css';

const Footer = (props) => {
    return (
        <div className={classes.root}>
            <Logo height='80px'/>
        </div>
    )
};

export default Footer;