import React from 'react';
import tableClasses from '../Table.module.css';
import {toMoneyString} from "../../../shared/aggregationUtils";
import Aux from "../../../hoc/Aux";

const EventRow = (props) => {
    let eventCounts = props.data.pages
        .reduce(function (acc, event) {
            if (typeof acc[event] == 'undefined') {
                acc[event] = 1;
            } else {
                acc[event] += 1;
            }
            return acc;
        }, {});

    const events = Object.keys(eventCounts).map(event => {
        return (
            <tr className={tableClasses.row + " " + tableClasses.tinyRow} key={event}>
                <td>{event}</td>
                <td>{eventCounts[event]}</td>
            </tr>
        )
    });

    const products = props.data.products.map(product => {
        return (
            <tr className={tableClasses.row + " " + tableClasses.tinyRow} key={product.tag}>
                <td>{product.tag}</td>
                <td>{toMoneyString(product.price)}</td>
                <td>{product.created.replace("T", " ").substring(0, 16)}</td>
            </tr>
        )
    });

    const cartValue = props.data.products.map(p => p.price).reduce((acc, value) => acc + value, 0);
    const email = props.data.email === "" ? "Undefined" : props.data.email;
    const lastActivity = props.data.lastActivity.replace("T", " ").substring(0, 16);
    return (
        <Aux>
            <tr className={tableClasses.row + " " + tableClasses.selectable + " " + tableClasses.tinyRow} key={props.data.ip}
                onClick={props.clicked}>
                <td>{props.data.ip}</td>
                <td>{email}</td>
                <td>{props.data.platform}</td>
                <td>{props.data.sources.join(", ")}</td>
                <td>{toMoneyString(cartValue)}</td>
                <td>{lastActivity}</td>
            </tr>

            {props.active ?
                <Aux>
                    <tr className={tableClasses.nestingHeaderRow}>
                        <th colSpan={4}>Product</th>
                        <th colSpan={2}>Events</th>
                    </tr>
                    <tr className={tableClasses.nestingRow}>
                        <td colSpan={4} className={tableClasses.nestingTd}>
                            <table className={tableClasses.nestedTable}>
                                <tbody>
                                <tr className={tableClasses.nestedHeaderRow}>
                                    <th>Product</th>
                                    <th>Price</th>
                                    <th>Time Bought</th>
                                </tr>
                                {products}
                                </tbody>
                            </table>
                        </td>
                        <td colSpan={2} className={tableClasses.nestingTd}>
                            <table className={tableClasses.nestedTable}>
                                <tbody>
                                <tr className={tableClasses.nestedHeaderRow}>
                                    <th>Event</th>
                                    <th>Times Fired</th>
                                </tr>
                                {events}
                                </tbody>
                            </table>
                        </td>
                    </tr>
                </Aux> : null}
        </Aux>
    );
};

export default EventRow;
