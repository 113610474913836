import React from 'react';
import classes from "../Table.module.css";
import {
    getAverageOrderValue,
    getAverageValuePerClick,
    getTotalOrderValue,
    getUniqueCount,
    toMoneyString
} from "../../../shared/aggregationUtils";
import SourceTable from "../Table/SourceTable";
import PlatformTable from "../Table/PlatformTable";
import ProductsTable from "../Table/ProductsTable";
import Aux from "../../../hoc/Aux";

const Overview = (props) => {
    const avc = toMoneyString(getAverageValuePerClick(props.data));
    const tov = toMoneyString(getTotalOrderValue(props.data));
    const aov = toMoneyString(getAverageOrderValue(props.data));
    const uniques = getUniqueCount(props.data);
    return (
        <Aux>
            <ProductsTable data={props.data}/>

            <table className={classes.table}>
                <tbody>
                <tr className={classes.headerRow}>
                    <th> </th>
                    <th>Unique</th>
                    <th>AVC</th>
                    <th>TOV</th>
                    <th>AOV</th>
                </tr>
                <tr className={classes.row} key={"total"}>
                    <th>Total</th>
                    <td>{uniques}</td>
                    <td>{avc}</td>
                    <td>{tov}</td>
                    <td>{aov}</td>
                </tr>
                <SourceTable data={props.data} setRemovedSources={props.setRemovedSources} removedSources={props.removedSources}/>
                <PlatformTable data={props.data}/>
                </tbody>
            </table>
        </Aux>
    );
};

export default Overview;
