import React from 'react';
import classes from "../Table.module.css";
import {
    getAverageOrderValue,
    getAverageValuePerClick,
    getTotalOrderValue,
    getUniqueCount,
    toMoneyString
} from "../../../shared/aggregationUtils";
import Aux from "../../../hoc/Aux";


const GetSpend = (props) => {
    const avc = toMoneyString(getAverageValuePerClick(props.data));
    const tov = toMoneyString(getTotalOrderValue(props.data));
    const aov = toMoneyString(getAverageOrderValue(props.data));
    const uniques = getUniqueCount(props.data);
    let spendData = [];
    if (props.spendData != null) {
        spendData = props.spendData
    }
    const spentTotal = spendData.map(val => val.spend).reduce((total, val) => total + val, 0);

    return (
<Aux>
    <div>FB AD SPEND:  {spentTotal}</div>

        <div className={classes.globalView}>
        <table className={classes.totalTable}>
        <colgroup>
        <col className={classes.blue} />
        <col className={classes.blue} />
        <col className={classes.blue} />
        <col className={classes.green} />
        <col className={classes.green} />
        </colgroup>
            <thead>
            <tr className={classes.headerRow}>
            <th> </th>
            <th>Unique</th>
            <th>AVC</th>
            <th>TOV</th>
            <th>AOV</th>
            </tr>
            </thead>
            <tbody>
            <tr className={classes.row} key={"total"}>
            <th>Total</th>
            <td>{uniques}</td>
            <td>{avc}</td>
            <td>{tov}</td>
            <td>{aov}</td>
            </tr>
            </tbody>
        </table>
    </div>
</Aux>
    );
};

export default GetSpend;
