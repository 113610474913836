// State
export var JOIN_BUTTON_CLICKED = 'JOIN_BUTTON_CLICKED';
export var AFFILIATE_LOADED = "AFFILIATE_LOADED";
export var LOAD_PAGE = 'LOAD_PAGE';
export var PIXEL_FIRED = 'PIXEL_FIRED';
export var GO_TO_HOME_PAGE = 'GO_TO_HOME_PAGE';
// Customer
export var SET_CONTACT_INFO_START = 'SET_CONTACT_INFO_START';
export var SET_CONTACT_INFO_SUCCESS = 'SET_CONTACT_INFO_SUCCESS';
export var SET_CONTACT_INFO_FAIL = 'SET_CONTACT_INFO_FAIL';
export var SET_CONTACT_SOURCE = 'SET_CONTACT_SOURCE';
// Product
export var ADD_PRODUCT_SKIP = 'ADD_PRODUCT_SKIP';
export var GET_PRODUCTS_START = 'GET_PRODUCTS_START';
export var GET_PRODUCTS_SUCCESS = 'GET_PRODUCTS_SUCCESS';
export var GET_PRODUCTS_FAIL = 'GET_PRODUCTS_FAIL';
// Analytics
export var GET_ANALYTICS_START = 'GET_ANALYTICS_START';
export var GET_ANALYTICS_SUCCESS = 'GET_ANALYTICS_SUCCESS';
export var GET_ANALYTICS_FAIL = 'GET_ANALYTICS_FAIL';
export var GET_LEGACY_ANALYTICS_START = 'GET_LEGACY_ANALYTICS_START';
export var GET_LEGACY_ANALYTICS_SUCCESS = 'GET_LEGACY_ANALYTICS_SUCCESS';
export var GET_LEGACY_ANALYTICS_FAIL = 'GET_LEGACY_ANALYTICS_FAIL';
export var GET_SPEND_START = 'GET_SPEND_START';
export var GET_SPEND_SUCCESS = 'GET_SPEND_SUCCESS';
export var GET_SPEND_FAIL = 'GET_SPEND_FAIL';
// Split
export var SPLIT_TESTS_LOADED = 'SPLIT_TESTS_LOADED';
// BrainTree
export var GET_BRAIN_TREE_TOKEN_START = 'GET_BRAIN_TREE_TOKEN_START';
export var GET_BRAIN_TREE_TOKEN_SUCCESS = 'GET_BRAIN_TREE_TOKEN_SUCCESS';
export var GET_BRAIN_TREE_TOKEN_FAIL = 'GET_BRAIN_TREE_TOKEN_FAIL';
export var SET_BRAIN_TREE_NONCE_START = 'SET_BRAIN_TREE_NONCE_START';
export var SET_BRAIN_TREE_NONCE_SUCCESS = 'SET_BRAIN_TREE_NONCE_SUCCESS';
export var SET_BRAIN_TREE_NONCE_FAIL = 'SET_BRAIN_TREE_NONCE_FAIL';
export var BUY_BRAIN_TREE_PRODUCTS_START = 'BUY_BRAIN_TREE_PRODUCTS_START';
export var BUY_BRAIN_TREE_PRODUCTS_SUCCESS = 'BUY_BRAIN_TREE_PRODUCTS_SUCCESS';
export var BUY_BRAIN_TREE_PRODUCTS_FAIL = 'BUY_BRAIN_TREE_PRODUCTS_FAIL';
export var INVALIDATE_BRAIN_TREE_TOKEN = 'INVALIDATE_BRAIN_TREE_TOKEN';
export var PAYMENT_METHOD_USED = 'PAYMENT_METHOD_USED';
// MixPanel
export var SEND_EVENT = "SEND_EVENT";
// Order Forms
export var GET_ORDER_FORM_START = 'GET_ORDER_FORM_START';
export var GET_ORDER_FORM_SUCCESS = 'GET_ORDER_FORM_SUCCESS';
export var GET_ORDER_FORM_FAIL = 'GET_ORDER_FORM_FAIL';
export var BUY_ORDER_FORM_START = 'BUY_ORDER_FORM_START';
export var BUY_ORDER_FORM_SUCCESS = 'BUY_ORDER_FORM_SUCCESS';
export var BUY_ORDER_FORM_FAIL = 'BUY_ORDER_FORM_FAIL';
