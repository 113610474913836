import React, {Component} from 'react';

class BundlePricing extends Component {

    render() {
        return (
            <div>BundlePricing</div>
        );
    }
}

export default BundlePricing;
