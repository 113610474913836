import React, {Component} from 'react';
import classes from './Header.module.css';
import Logo from "./Logo";

class Header extends Component {
    render() {
        return (
            <div className={classes.root}>
                <div className={classes.subRoot}>
                    <Logo/>
                </div>
            </div>
        )
    }
}

export default Header;
