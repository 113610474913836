import React, {Component} from 'react';
import classes from './Controls.module.css';

class RemovedRows extends Component {

    resetSource(source) {
        const sources = this.props.removedSources.filter(s => s !== source);
        this.props.setRemovedSources(sources);
        localStorage.setItem("removedSourceRows", JSON.stringify(sources));
    }

    render() {
        const displaySources = this.props.removedSources.map(s =>
            <li className={classes.removedSource} key={s} onClick={() => this.resetSource(s)}>
                {s}
            </li>);
        return (
            <ul className={classes.removedSources}>{displaySources}</ul>
        );
    }
}

export default RemovedRows;
