import React, {Component} from 'react';
import classes from '../Table.module.css';
import {
    getAverageOrderValue,
    getAverageValuePerClick,
    getTotalOrderValue,
    getUniqueCount,
    toMoneyString
} from "../../../shared/aggregationUtils";
import Aux from "../../../hoc/Aux";
import {FaTimes} from "react-icons/fa";

class SourceTable extends Component {

    componentDidMount() {
        const val = localStorage.getItem("removedSourceRows");
        if (val !== null) {
            this.props.setRemovedSources(JSON.parse(val));
        }
    }

    handleDelete(sourceTag) {
        const copy = this.props.removedSources.slice();
        copy.push(sourceTag);
        this.props.setRemovedSources(copy);
        localStorage.setItem("removedSourceRows", JSON.stringify(copy));
    }

    render() {
        const sources = this.props.data.flatMap(v => v.sources).filter((v, i, a) => a.indexOf(v) === i);
        const sourceFilter = e => v => v.sources.includes(e);
        const salesFilter = e => v => (v.sources.includes(e) && v.products.length !== 0);
        const sourceTotals = sources.filter(e => !this.props.removedSources.includes(e)).map(e =>
            <tr className={classes.row} key={e}>
                <th>{e}</th>
                <td>{getUniqueCount(this.props.data, sourceFilter(e))}</td>
                <td>{toMoneyString(getAverageValuePerClick(this.props.data, sourceFilter(e)))}</td>
                <td>{toMoneyString(getTotalOrderValue(this.props.data, sourceFilter(e)))}</td>
                <td>{toMoneyString(getAverageOrderValue(this.props.data, sourceFilter(e)))}</td>
                <td>{getUniqueCount(this.props.data, salesFilter(e))}</td>
                <td className={classes.removeCell}><FaTimes className={classes.removeIcon} onClick={() => this.handleDelete(e)}/></td>
            </tr>
        );

        return (
            <Aux>
                <tr className={classes.headerRow}>
                    <th>Source</th>
                    <th>Unique</th>
                    <th>AVC</th>
                    <th>TOV</th>
                    <th>AOV</th>
                    <th>Sales</th>
                </tr>
                {sourceTotals}
            </Aux>
        );
    }
}

export default SourceTable;
