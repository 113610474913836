import React from 'react';
import classes from "./Effective.module.css";

const Reason = (props) => {
    return (
        <li className={classes.reason}>
            <div className={classes.reasonHeader}>
                {props.children}
                <p className={classes.reasonTitle}>{props.title}</p>
            </div>
            <div className={classes.reasonBody}>
                {props.body}
            </div>
        </li>
    );
};

export default Reason;