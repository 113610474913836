import React, {Component} from 'react';
import classes from './CreditCardForm.module.css';
import {IoMdCheckmark} from "react-icons/io";
import {connect} from "react-redux";
import * as actions from "../../../../store/actions";
import CreditForm from "../CreditForm/CreditForm";
import * as productTags from "../../../../shared/products";
import SecureImage from "../../../../assets/images/secure-logos.png";
import {FAST_TRACK, MONTHLY, OPT_IN, QUARTERLY, SUBMIT_CREDIT, YEARLY} from "../../../../shared/events";
import Aux from "../../../../hoc/Aux";
import PageSpinner from "../../../Spinner/PageSpinner";
import _ from "lodash"
import EnrollContact from "../../../LinkedIn/EnrollContact";
import ReactPixel from "react-facebook-pixel";
import {FaRegCheckSquare, FaRegSquare} from "react-icons/fa";


class CreditCardForm extends Component {

    state = {
        itemSelected: null,
        fastTrackSelected: false,
        itemError: '',
        brainTreeInstance: null,
        brainTreeReady: false,
        price: 0,
        productNames: "",
        popupShowing: false,
        popupDone: false,
        quarterlyShowing: false,
        buttonDisabled: false,
        failPaymentPopupShowing: false
    };

    componentDidMount() {
        this.props.sendEvent(OPT_IN);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        console.log("first name ", this.props.contact);
        const paymentRequest = {
            total: {
                label: this.state.productNames,
                amount: this.state.price
            },
            requiredBillingContactFields: ["postalAddress"]
        };
        if (this.state.brainTreeInstance) {
            console.log(process.env.NODE_ENV);
            console.log(this.state.brainTreeInstance);
            this.state.brainTreeInstance.updateConfiguration('applePay', 'paymentRequest', paymentRequest);
        }

        if (this.props.creditError) {




        }


    }

    handleAnnualClick(event) {
        let price;
        let names;
        if (this.state.fastTrackSelected) {
            price = 95 + this.props.split.annualPrice;
            names = "Annual Subscription & Fast-Track"
        } else {
            price = this.props.split.annualPrice;
            names = "Annual Subscription"
        }
        this.setState({
            ...this.state,
            itemSelected: "annual",
            price: price,
            productNames: names
        })
    }

    handleQuarterlyClick(event) {
        let price;
        let names;
        if (this.state.fastTrackSelected) {
            price = 95 + this.props.split.quarterlyPrice;
            names = "Quarterly Subscription & Fast-Track"
        } else {
            price = this.props.split.quarterlyPrice;
            names = "Quarterly Subscription"
        }
        this.setState({
            ...this.state,
            itemSelected: "quarterly",
            price: price,
            productNames: names
        })
    }

    handleMonthlyClick(event) {
        let price;
        let names;
        if (this.state.fastTrackSelected) {
            price = 95 + this.props.split.price;
            names = "Monthly Subscription & Fast-Track"
        } else {
            price = this.props.split.price;
            names = "Monthly Subscription"
        }
        this.setState({
            ...this.state,
            itemSelected: "monthly",
            popupShowing: !this.state.popupDone,
            price: price,
            productNames: names
        })
    }

    handleFastTrackClick(event) {
        this.setState({
            ...this.state,
            fastTrackSelected: !this.state.fastTrackSelected
        })
    }

    handleModalYes() {
        this.setState({
            ...this.state,
            popupShowing: false,
            quarterlyShowing: true,
            popupDone: true,
            itemSelected: "quarterly",
        })
    }

    handleFailedPaymentYes(){
        this.setState({
            ...this.state,
            failPaymentPopupShowing: false
        });
        console.log("credit error");
        const name = localStorage.getItem("contactName");
        const email = localStorage.getItem("contactEmail");
        const phone = localStorage.getItem("contactPhone");

        window.prefillZendesk(name, email, phone);

    }

    handleModalNo() {
        this.setState({
            ...this.state,
            popupShowing: false,
            popupDone: true,
            quarterlyShowing: true,
        })
    }

    handleFailPaymentNo() {
        this.setState({
            ...this.state,
            failPaymentPopupShowing: false
        })
    }

    static validateItem(val) {
        if (val === null) {
            return 'Select a product';
        }
        return '';
    }

    setBrainTreeInstance(instance) {
        const transactionInformation = {

            currencyCode: "USD",
            totalPriceStatus: "NOT_CURRENTLY_KNOWN"
        };
        instance.updateConfiguration('googlePay', 'transactionInfo', transactionInformation);
        instance.on('paymentMethodRequestable', (event) => {
            this.setState({
                ...this.state,
                brainTreeReady: true
            })
        });
        this.setState({
            ...this.state,
            brainTreeInstance: instance,
            brainTreeReady: instance.isPaymentMethodRequestable()
        });
    }

    submit() {
        this.props.sendEvent(SUBMIT_CREDIT);
        ReactPixel.track('AddPaymentInfo', {content_name: 'wolfnetwork70off'});

        let itemError = CreditCardForm.validateItem(this.state.itemSelected);

        if (itemError !== '') {
            this.setState({
                ...this.state,
                itemError: itemError,
            });
            return;
        } else {
            this.setState({
                ...this.state,
                buttonDisabled: true
            });
            setTimeout(() => this.setState({
                ...this.state,
                buttonDisabled: false
            }), 3000);
        }

        if (this.state.brainTreeInstance) {
            const transactionInformation = {
                currencyCode: "USD",
                totalPriceStatus: "FINAL",
                totalPrice: this.state.price
            };
            this.state.brainTreeInstance.requestPaymentMethod((err, payload) => {
                this.state.brainTreeInstance.updateConfiguration('googlePay', 'transactionInfo', transactionInformation);
                if (err) {
                    console.warn(err);
                    this.setState({
                        ...this.state,
                        failPaymentPopupShowing: true
                    });
/*
                    if (this.state.failPaymentPopupShowing) {

                        console.log("credit error");
                        const name = "dan";
                        const email = "dan@jb.com";
                        const phone = "+13238074248";
                        window.prefillZendesk(name, email, phone);
                    }*/




                } else {
                    const paymentMethodType = this.state.brainTreeInstance._model.getActivePaymentMethod().type;
                    if (paymentMethodType === 'AndroidPayCard' || paymentMethodType === "VenmoAccount") {
                        this.props.setPaymentMethod(paymentMethodType);
                    }
                    this.submitNonce(payload.nonce)
                }
            })
        } else {
            console.warn("Instance not loaded");
        }
    }

    submitNonce(nonce) {
        let products = [];
        if (this.state.itemSelected === 'annual') {
            products.push(this.props.split.annualProduct);
            this.props.sendEvent(YEARLY);
        } else if (this.state.itemSelected === 'monthly') {
            products.push(this.props.split.monthlyProduct);
            this.props.sendEvent(MONTHLY);
        } else if (this.state.itemSelected === 'quarterly') {
            products.push(this.props.split.quarterlyProduct);
            this.props.sendEvent(QUARTERLY);
        }
        if (this.state.fastTrackSelected) {
            products.push(productTags.FAST_TRACK);
            this.props.sendEvent(FAST_TRACK)
        }
        const trackPixel = true;
        this.props.setNonceAndBuy(nonce, products, this.props.transactionProperties, trackPixel)
    }

    static isOfficeHours() {
        let laTime = new Date().toLocaleString("en-US", {timeZone: "America/Los_Angeles"});
        laTime = new Date(laTime);
        const isWeekDay = laTime.getDay() !== 0 && laTime.getDay() !== 6;
        const isBetween9And6 = laTime.getHours() > 9 && laTime.getHours() < 18;
        return isWeekDay && isBetween9And6;
    }

    render() {
        const daysText = this.props.split.days === 1 ? "1 Day" : this.props.split.days + " Days";
        const showLoopText = CreditCardForm.isOfficeHours();
        return (
            <div className={classes.root}>

                <EnrollContact/>
                {/*<LeadPixel/>*/}
                {this.props.creditLoading ? <PageSpinner/> : null}
                {this.state.popupShowing ? <Aux>
                    <div className={classes.backdrop} onClick={() => this.handleModalNo()}/>
                    <div className={classes.modalBox}>
                        <h1>Get an Extra {this.props.split.percentageOffQuarterly}% Off Now!</h1>
                        <p>Change my payment to quarterly and still get 35% off</p>
                        <button className={classes.modalYes} onClick={() => this.handleModalYes()}>
                            Yes, I Want {this.props.split.percentageOffQuarterly}% Off!
                        </button>
                        <button className={classes.modalNo} onClick={() => this.handleModalNo()}>
                            No, I Don't Want {this.props.split.percentageOffQuarterly}% Off.
                        </button>
                    </div>
                </Aux> : null}


                {this.state.failPaymentPopupShowing || this.props.creditError ? <Aux>
                    <div className={classes.backdrop} onClick={() => this.handleFailPaymentNo()}/>

                    <div className={classes.modalBox}>
                        <h1>Hmm, we've hit a snag processing your payment..</h1>
                        <p>No worries, you can chat with one of our representatives to assist you! </p>
                        <button className={classes.modalYes} onClick={() => this.handleFailedPaymentYes()}>
                            Chat With A Rep
                        </button>
                        <button className={classes.modalNo} onClick={() => this.handleFailPaymentNo()}>
                            Try Another Card
                        </button>
                    </div>

                </Aux> : null}


                <div className={classes.itemButtons}>


                    <div className={classes.yearlyDealRow}>
                        <div className={classes.bestDealText}> Your Best Deal By Far
                            <div className={classes.yearlyCheckLineBoxLeft}>
                                <div className={classes.ftCheckLine}/>
                                <div className={classes.ftCheckArrowHeadLeft}/>
                            </div>
                        </div>


                        <button
                            className={
                                classes.itemButton + ' ' + classes.pulse +
                                (this.state.itemSelected === 'annual' ? ' ' + classes.activeButton : '') +
                                (this.state.itemError !== '' && this.state.itemSelected === null ? ' ' + classes.errorItemButton : '')
                            }
                            onClick={(event) => this.handleAnnualClick(event)}
                        >
                            {/*
                        <div className={classes.mostPopular}>Most Popular</div>
*/}
                            <div className={classes.checkHolder}>
                                {this.state.itemSelected === 'annual' ? <FaRegCheckSquare className={classes.check}/> :
                                    <FaRegSquare className={classes.check}/>}
                            </div>

                            {this.props.split.days > 0 ?
                                <div className={classes.itemButtonText}>

                                    <b className={classes.membershipType}>Annual Membership</b> <br/>
                                    <b className={classes.normalPrice}>
                                        Normal Price: <span>$329.97</span>
                                    </b>
                                    <br/>
                                    <b className={classes.todaysPrice}>
                                        Your Price Today: Only $97
                                    </b>

                                </div> :
                                <div className={classes.itemButtonText}>
                                    <b className={classes.membershipType}>Annual Membership</b> <br/>
                                    <b className={classes.normalPrice}>
                                        Normal Price: <span>$329.97</span>
                                    </b>
                                    <br/>
                                    <b className={classes.todaysPrice}>
                                        Your Price Today: Only $97
                                    </b>

                                </div>}
                            <div className={classes.bannerBox}>
                                <div className={classes.banner}>{this.props.split.percentageOff}% Off</div>
                            </div>
                            <div className={classes.bannerBoxLeft}>
                                <div className={classes.bannerLeft}>{this.props.split.percentageOff}% Off</div>
                            </div>
                            <div className={classes.fakeButton}>Select Plan</div>
                        </button>


                        <div className={classes.bestDealText}> Your Best Deal By Far

                            <div className={classes.annualCheckLineBoxRight}>
                                <div className={classes.ftCheckArrowHeadRight}/>
                                <div className={classes.ftCheckLine}/>
                            </div>
                        </div>


                    </div>
                    {this.state.quarterlyShowing ?
                        <button
                            className={
                                classes.itemButtonSmall + " " + classes.popupButton +
                                (this.state.itemSelected === 'quarterly' ? ' ' + classes.activeButton : '') +
                                (this.state.itemError !== '' && this.state.itemSelected === null ? ' ' + classes.errorItemButton : '')
                            }
                            onClick={(event) => this.handleQuarterlyClick(event)}>
                            <div className={classes.checkHolder}>
                                {this.state.itemSelected === 'quarterly' ?
                                    <FaRegCheckSquare className={classes.check}/> :
                                    <FaRegSquare className={classes.check}/>}
                            </div>
                            <div className={classes.itemButtonTextSmall}>


                                <b className={classes.membershipTypeSmall}>Quarterly Membership</b> <br/>
                                <b className={classes.normalPrice}>
                                    Normal Price: <span>$89.97</span>
                                </b>
                                <br/>
                                <b className={classes.todaysPricesmall}>
                                    Your Price Today: Only $59
                                </b>


                            </div>

                            <div className={classes.bannerBoxLeft}>
                                <div className={classes.bannerLeft}>{this.props.split.percentageOffQuarterly}% Off</div>
                            </div>
                            <div className={classes.bannerBox}>
                                <div className={classes.banner}>{this.props.split.percentageOffQuarterly}% Off</div>
                            </div>

                            <div className={classes.fakeButton}>Select Plan</div>
                        </button> :
                        null
                    }

                    {this.state.quarterlyShowing ? null : <div className={classes.orOption}>OR</div>}

                    <button
                        className={
                            classes.itemButtonSmaller +
                            (this.state.itemSelected === 'monthly' ? ' ' + classes.activeButton : '') +
                            (this.state.itemError !== '' && this.state.itemSelected === null ? ' ' + classes.errorItemButton : '')
                        }
                        onClick={(event) => this.handleMonthlyClick(event)}
                    >
                        <div className={classes.checkHolder}>
                            {this.state.itemSelected === 'monthly' ? <FaRegCheckSquare className={classes.check}/> :
                                <FaRegSquare className={classes.check}/>}
                        </div>
                        <div className={classes.itemButtonText}>
                            {this.props.split.days > 0 ?
                                <p className={classes.monthlySub}>Monthly Membership: <b>FREE for {daysText}</b>, then
                                    ${this.props.split.price} per month</p>
                                : <p className={classes.monthlySub}>Monthly Membership: ${this.props.split.price} per
                                    month</p>
                            }
                        </div>
                        <div className={classes.fakeButton}>Select Plan</div>
                    </button>
                </div>
                {this.state.itemError !== '' && this.state.itemSelected === null ?
                    <div className={classes.itemError}>{this.state.itemError}</div> : null}

                {/*

                <h1 className> Payment Information </h1>


*/}

                <div className={classes.creditFormBlock}>
                    <CreditForm
                        brainTreeInstance={this.state.brainTreeInstance}
                        setBrainTreeInstance={(instance) => this.setBrainTreeInstance(instance)}
                        price={this.state.price}
                        product={this.state.productNames}
                    />
                    {showLoopText ?
                        <React.Fragment>
                            <p className={classes.loopTitle}>Have Questions?</p>
                            <p className={classes.loopText}>Wish to Speak to a <b>Live Rep?</b> Just
                                text <b>WOLF</b> to <b>1-310-736-2296</b> and one of our experts will call you right
                                back.</p>
                        </React.Fragment> : null
                    }
                </div>
                <div className={classes.fastTrackBlock}>
                    <div className={classes.ftCheckBlock}>
                        <div className={classes.ftCheckRow}>
                            <div className={classes.ftCheckLineBoxLeft}>
                                <div className={classes.ftCheckLine}/>
                                <div className={classes.ftCheckArrowHeadLeft}/>
                            </div>
                            <div className={classes.ftCheckbox} onClick={(event) => this.handleFastTrackClick(event)}>
                                {this.state.fastTrackSelected ? <IoMdCheckmark className={classes.ftCheck}/> : null}
                            </div>

                            <div className={classes.ftCheckLineBoxRight}>
                                <div className={classes.ftCheckArrowHeadRight}/>
                                <div className={classes.ftCheckLine}/>
                            </div>
                        </div>
                        <p className={classes.offerTitle}>
                            Yes, I also want the the Straight Line Persuasion Fast Track For Only $97
                        </p>
                    </div>
                    <p className={classes.offerText}>
                        Designed to give you near-instant results, this world-class sales training program works
                        hand-in-hand with the rest of the Wolf Network, to rapidly turn you into a top-producer in your
                        field. Normally priced at $329, this $97 offer represents a massive 70%
                        discount. JB considers Fast Track a required course, because of how massively it amplifies your
                        results, so take action RIGHT NOW to literally ensure your success.
                    </p>
                </div>
                {this.props.creditError ?

                    <div className={classes.serverError}>{this.props.creditError}</div>
                    :
                    null}

                {<button className={classes.submit} disabled={!this.state.brainTreeReady || this.state.buttonDisabled}
                         onClick={_.debounce(() => this.submit(), 300, {
                             'leading': true,
                             'trailing': false
                         })}>Complete Order</button>}
                <p className={classes.safeText}>* 100% Secure & Safe Payments *</p>
                <div className={classes.imageBox}>
                    <img className={classes.image} src={SecureImage} alt="security verified"/>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        creditLoading: state.braintree.loading,
        creditError: state.braintree.error,
        transactionProperties: state.mixPanel.superProps,
        paymentMethod: state.braintree.paymentMethod,
        contact: state.contact.emailAddress
    };
};

const mapDispatchToProps = dispatch => {
    return {
        sendEvent: (eventTag) => {
            dispatch(actions.sendAnalytics(eventTag));
            dispatch(actions.sendEvent(eventTag))
        },
        setNonceAndBuy: (nonce, products, transactionProperties, trackPixel) => dispatch(actions.setNonceAndBuy(nonce, products, transactionProperties, trackPixel)),
        setPaymentMethod: (description) => dispatch(actions.paymentMethodUsed(description))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreditCardForm);
