import React from 'react';
import classes from "../Landing.module.css";

const FloatingButton = (props) => {
    const hasTrial = props.split.days > 0;
    const buttonTitle = hasTrial ? 'Join Right Now' : 'Enroll Now';
    return (
        <React.Fragment>
            <div className={classes.floatButtonBox} onClick={() => props.buttonClicked()}>
                <button className={classes.floatButton}>{buttonTitle}</button>
            </div>
            <div className={classes.floatPlaceholder}/>
        </React.Fragment>
    )
};

export default FloatingButton;
