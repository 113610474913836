import * as actionTypes from '../actions/actionTypes';
import {sendSmsPostBack, updateObject} from '../../shared/utility';
import * as productTags from '../../shared/products';
import {subscriptionProducts} from '../../shared/products';

const initialState = {
    homeDone: false,
    contactDone: false,
    creditDone: false,
    upsellDone: false,
    downsellDone: false,
    slpDone: false,
    manualDone: false,
    loaded: false,
    pixelFired: false,
    affiliateMemberId: null
};

const joinButtonClicked = ( state, action) => {
    if (state.contactDone) {
        localStorage.setItem('page', "credit");
    } else {
        localStorage.setItem('page', "contact");
    }
    return updateObject( state, {
        homeDone: true
    });
};

const goToHomePage = ( state, action) => {
    localStorage.setItem('page', "home");
    return updateObject( state, {
        homeDone: false
    });
};

const contactDone = ( state, action) => {
    localStorage.setItem('page', "credit");
    return updateObject( state, {
        contactDone: true
    })
};

const pixelFired = ( state, action) => {
    return updateObject( state, {
        pixelFired: true
    })
};

const buyBrainTreeProductsSuccess = (state, action) => {
    if (state.manualDone) {
        return state;
    }

    if (action.products.some(p => subscriptionProducts.includes(p))) {
        const smsId = localStorage.getItem("smsId");
        if (smsId !== null) {
            sendSmsPostBack(smsId)
        }
        localStorage.setItem('page', "upsell");
        return updateObject( state, {
            creditDone: true,
            affiliateMemberId: localStorage.getItem("email")
        });
    } else if (action.products.includes(productTags.SCRIPT_BUILDER_UPSELL)) {
        localStorage.setItem('page', "slp");
        return updateObject( state, {
            upsellDone: true,
            downsellDone: true
        });
    } else if (action.products.includes(productTags.SCRIPT_BUILDER_DOWNSELL)) {
        localStorage.setItem('page', "slp");
        return updateObject( state, {
            downsellDone: true
        });
    } else if (action.products.includes(productTags.STRAIGHT_LINE_PERSUASION) || action.products.includes(productTags.STRAIGHT_LINE_PERSUASION_SUB)) {
        localStorage.setItem('page', "manual");
        return updateObject( state, {
            slpDone: true
        });
    } else if (action.products.includes(productTags.SO_MANUAL)) {
        localStorage.setItem('page', "thank-you");
        return updateObject( state, {
            manualDone: true
        });
    }
    return state;
};

const productSkip = ( state, action) => {
    if (!state.upsellDone) {
        localStorage.setItem('page', 'downsell');
    }
    if (state.upsellDone) {
        localStorage.setItem('page', 'slp');
    }
    if (state.downsellDone) {
        localStorage.setItem('page', 'manual');
    }
    if (state.slpDone) {
        localStorage.setItem('page', 'thank-you');
    }

    return updateObject( state, {
        upsellDone: true,
        downsellDone: state.upsellDone,
        slpDone: state.downsellDone,
        manualDone: state.slpDone
    })
};

const loadPage = ( state, action) => {
    if (action.page === 'contact') {
        return updateObject( state, {
            homeDone: true,
            loaded: true
        })
    } else if (action.page === 'credit') {
        return updateObject( state, {
            homeDone: true,
            contactDone: true,
            loaded: true
        })
    } else if (action.page === 'upsell') {
        return updateObject( state, {
            homeDone: true,
            contactDone: true,
            creditDone: true,
            loaded: true
        })
    } else if (action.page === 'downsell') {
        return updateObject( state, {
            homeDone: true,
            contactDone: true,
            creditDone: true,
            upsellDone: true,
            loaded: true
        })
    } else if (action.page === 'slp') {
        return updateObject( state, {
            homeDone: true,
            contactDone: true,
            creditDone: true,
            upsellDone: true,
            downsellDone: true,
            loaded: true
        })
    } else if (action.page === 'manual') {
        return updateObject( state, {
            homeDone: true,
            contactDone: true,
            creditDone: true,
            upsellDone: true,
            downsellDone: true,
            slpDone: true,
            loaded: true
        })
    } else if (action.page === 'thank-you') {
        return updateObject( state, {
            homeDone: true,
            contactDone: true,
            creditDone: true,
            upsellDone: true,
            downsellDone: true,
            slpDone: true,
            manualDone: true,
            loaded: true
        })
    }
    return updateObject(state, {
        loaded: true
    });
};

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.JOIN_BUTTON_CLICKED: return joinButtonClicked(state, action);
        case actionTypes.SET_CONTACT_INFO_SUCCESS: return contactDone(state, action);
        case actionTypes.ADD_PRODUCT_SKIP: return productSkip(state, action);
        case actionTypes.LOAD_PAGE: return loadPage(state, action);
        case actionTypes.PIXEL_FIRED: return pixelFired(state, action);
        case actionTypes.GO_TO_HOME_PAGE: return goToHomePage(state, action);
        case actionTypes.BUY_BRAIN_TREE_PRODUCTS_SUCCESS: return buyBrainTreeProductsSuccess(state, action);
        default:
            return state;
    }
};

export default reducer;
