import React, {Component} from 'react';
import {FaAngleLeft} from "react-icons/fa";
import classes from './Carousel.module.css';
import {FaAngleRight} from "react-icons/fa/index";
import VimeoPlayer from "../Vimeo/VimeoPlayer";

class Carousel extends Component {

    state = {
        current: 0
    };

    handlePrev() {
        let current = (this.state.current - 1);
        if (current === -1) {
            current = this.props.data.length - 1
        }
        this.setState({
            ...this.state,
            current: current
        })
    }

    handleNext() {
        this.setState({
            ...this.state,
            current: (this.state.current + 1) % this.props.data.length
        })
    }

    mapItem(item) {
        if (item.type === 'video') {
            return <VimeoPlayer
                width={507}
                height={282}
                threshold={800}
                multiplier={0.85}
                url={item.url}
            />
        } else {
            const text = item.text.split('\n').map((it, i) => <p className={classes.text} key={i}>{it}</p>);
            return <div className={classes.textRoot}>
                {text}
                <p className={classes.subtext}>{item.subText}</p>
            </div>;
        }
    }

    render() {
        if (!this.props.data) {
            return null;
        }
        let currentItem = this.props.data[this.state.current];
        let contents = this.mapItem(currentItem);

        return (
            <div className={classes.root}>
                <button className={classes.button + " " + classes.nonMobile} onClick={() => this.handlePrev()}>
                    <FaAngleLeft
                        className={classes.icon}/></button>
                <div>
                    {contents}
                </div>
                <button className={classes.button + " " + classes.nonMobile} onClick={() => this.handleNext()}>
                    <FaAngleRight
                        className={classes.icon}/></button>
                <div className={classes.mobileButtons}>
                    <button className={classes.button} onClick={() => this.handlePrev()}><FaAngleLeft
                        className={classes.icon}/></button>
                    <button className={classes.button} onClick={() => this.handleNext()}><FaAngleRight
                        className={classes.icon}/></button>
                </div>
                <div className={classes.buttonSubtext}>*For More Testimonials</div>
            </div>
        );
    }
}

export default Carousel;
