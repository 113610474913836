import React, {Component} from 'react';
import classes from './Analytics.module.css';
import {connect} from "react-redux";
import * as actions from "../../store/actions";
import PageSpinner from "../Spinner/PageSpinner";
import EventList from "./EventRow/EventList";
import Overview from "./Overview/Overview";
import Tabs from "./Controls/Tabs";
import GetControls from "./Controls/GetControls";
import EventsTable from "./Table/EventsTable";
import RemovedRows from "./Controls/RemovedRows";
import GetSpend from "./Controls/GetSpend";
import * as queryString from "query-string";

class Analytics extends Component {

    state = {
        startDate: Analytics.createDateString(),
        endDate: Analytics.createDateString(),
        startTime: "00:00",
        endTime: "23:59",
        activeTab: "overview",
        removedSources: []
    };

    componentDidMount() {
        this.handleSubmit();
    }

    static createDateString() {
        const dateString = new Date().toLocaleDateString().split("/");
        return dateString[2] + "-" + dateString[0].padStart(2, '0') + "-" + dateString[1].padStart(2, '0')
    }

    handleStartDateChange(event) {
        this.setState({
            ...this.state,
            startDate: event.target.value
        });
    }

    handleEndDateChange(event) {
        this.setState({
            ...this.state,
            endDate: event.target.value
        });
    }

    setRemovedSources(removedSources) {
        this.setState({
            ...this.state,
            removedSources: removedSources
        })
    }

    handleSubmit() {
        let query = this.props.location.search;
        const values = queryString.parse(query);
        if (!values.pw) {
            return;
        }
        const password = values.pw;
        let startDateSplit = this.state.startDate.split("-");
        let endDateSplit = this.state.endDate.split("-");
        let startTimeSplit = this.state.startTime.split(":");
        let endTimeSplit = this.state.endTime.split(":");
        const start = {
            year: startDateSplit[0],
            month: startDateSplit[1],
            day: startDateSplit[2],
            hour: startTimeSplit[0],
            minute: startTimeSplit[1]
        };
        const end = {
            year: endDateSplit[0],
            month: endDateSplit[1],
            day: endDateSplit[2],
            hour: endTimeSplit[0],
            minute: endTimeSplit[1]
        };
        this.props.getAnalytics(start, end, password);
        this.props.getFbSpend(start, end, password);
        this.props.getLegacyAnalytics(password);
    }

    setActiveTab(tab) {
        this.setState({
            ...this.state,
            activeTab: tab
        })
    }

    render() {
        let dataView = null;
        if (this.props.data) {
            if (this.state.activeTab === "overview") {
                dataView = <Overview
                    data={this.props.data.filter(row => row.sources.every(source => !this.state.removedSources.includes(source)))}
                    setRemovedSources={(sources) => this.setRemovedSources(sources)}
                    removedSources={this.state.removedSources}
                />
            } else if (this.state.activeTab === "events") {
                dataView = <EventList data={this.props.data.reverse()}/>;
            } else if (this.state.activeTab === "events-overview") {
                dataView = <EventsTable data={this.props.data}/>
            } else if (this.state.activeTab === "removed-rows") {
                dataView = <RemovedRows removedSources={this.state.removedSources} setRemovedSources={(sources) => this.setRemovedSources(sources)}/>
            }
        }
        let spendView= null;
        if(this.props.data){

            spendView = <GetSpend
                data={this.props.data.filter(row => row.sources.every(source => !this.state.removedSources.includes(source)))}

                spendData={this.props.spendData}
                />
        }

        return (
            <div className={classes.root}>
                {this.props.loading ? <PageSpinner/> : null}
                <div className={classes.title}>Analytics</div>

                <div className={classes.subRoot}>
                    <GetControls
                        handleStartDateChange={event => this.handleStartDateChange(event)}
                        handleEndDateChange={event => this.handleEndDateChange(event)}
                        startDate={this.state.startDate}
                        endDate={this.state.endDate}
                        handleSubmit={() => this.handleSubmit()}
                    />
                    <Tabs activeTab={this.state.activeTab} setActiveTab={(tab) => this.setActiveTab(tab)}/>
                    <div className={classes.globalSpend}>
                        {spendView}
                    </div>
                    <div className={classes.dataRoot}>
                        {dataView}
                    </div>
                </div>
            </div>

        )
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state.analytics.loading || state.analytics.legacyLoading || state.analytics.spendLoading,
        data: state.analytics.data,
        legacyData: state.analytics.legacyData,
        error: state.analytics.error,
        legacyError: state.analytics.legacyError,
        spendData: state.analytics.spendData,
        spendError: state.analytics.spendError
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getAnalytics: (start, end, password) => dispatch(actions.getAnalytics(start, end, password)),
        getFbSpend: (start, end, password) => dispatch(actions.getFbSpend(start, end, password)),
        getLegacyAnalytics: (password) => dispatch(actions.getLegacyAnalytics(password))
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(Analytics);
