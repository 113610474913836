import * as actionTypes from "./actionTypes";
import {getData} from "../../shared/utility";

export const buyOrderFormStart = () => {
    return {
        type: actionTypes.BUY_ORDER_FORM_START
    };
};

export const buyOrderFormSuccess = () => {
    return {
        type: actionTypes.BUY_ORDER_FORM_SUCCESS
    };
};

export const buyOrderFormFail = (error) => {
    return {
        type: actionTypes.GET_ORDER_FORM_FAIL,
        error: error
    };
};

export const getOrderFormStart = () => {
    return {
        type: actionTypes.BUY_ORDER_FORM_START
    };
};

export const getOrderFormSuccess = (data) => {
    return {
        type: actionTypes.GET_ORDER_FORM_SUCCESS,
        data: data
    };
};

export const getOrderFormFail = (error) => {
    return {
        type: actionTypes.GET_ORDER_FORM_FAIL,
        error: error
    };
};

export const getOrderForm = (id) => {
    return dispatch => {
        dispatch(getOrderFormStart());
        getData(
            "/braintree/form/" + id,
            response => {
                dispatch(getOrderFormSuccess(response.data));
            },
            err => {
                dispatch(getOrderFormFail(err));
            });
    };
};
