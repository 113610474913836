import React, {Component} from 'react';
import classes from '../Table.module.css';
import {displayEvents, LANDING_PAGE} from "../../../shared/events";

class EventsTable extends Component {

    static getEventTotal(data, eventTag) {
        let filtered;
        if (eventTag === LANDING_PAGE) {
            filtered = data.filter(val => val.pages.includes(eventTag) || val.pages.includes("opt-in"));
        } else {
            filtered = data.filter(val => val.pages.includes(eventTag));
        }
        return filtered.reduce((acc, val) => {
            const eventCount = EventsTable.getEventCount(val.pages);
            const count = eventCount[eventTag] ? eventCount[eventTag] : 0;
            let legacyCount = 0;
            if (eventTag === LANDING_PAGE) {
                legacyCount = eventCount["opt-in"] ? eventCount["opt-in"] : 0;
            }
            return acc + count + legacyCount;
        }, 0)
    }

    static getEventUnique(data, eventTag) {
        return data.filter(val => val.pages.includes(eventTag)).length;
    }

    static getEventCount(eventList) {
        return eventList.reduce(function (acc, val) {
            if (typeof acc[val] == 'undefined') {
                acc[val] = 1;
            } else {
                acc[val] += 1;
            }
            return acc;
        }, {});
    }

    render() {
        const eventTotals = displayEvents.map(e =>
            <tr className={classes.row} key={e}>
                <th>{e}</th>
                <td>{EventsTable.getEventTotal(this.props.data, e)}</td>
                <td>{EventsTable.getEventUnique(this.props.data, e)}</td>
            </tr>);

        return (
            <table className={classes.table}>
                <tbody>
                <tr className={classes.headerRow}>
                    <th>Event Name</th>
                    <th>Total</th>
                    <th>Unique</th>
                </tr>
                <tr className={classes.row} key={"Funnel split-3days1495"}>
                    <th>{"3 Days Split"}</th>
                    <td>{EventsTable.getEventTotal(this.props.data, "Funnel split-3days1495")}</td>
                    <td>{EventsTable.getEventUnique(this.props.data, "Funnel split-3days1495")}</td>
                </tr>
                <tr className={classes.row} key={"Funnel split-7days1495"}>
                    <th>{"7 Days Split"}</th>
                    <td>{EventsTable.getEventTotal(this.props.data, "Funnel split-7days1495")}</td>
                    <td>{EventsTable.getEventUnique(this.props.data, "Funnel split-7days1495")}</td>
                </tr>
                <tr className={classes.row} key={"Funnel split-1day1495"}>
                    <th>{"1 Day Split"}</th>
                    <td>{EventsTable.getEventTotal(this.props.data, "Funnel split-1day1495")}</td>
                    <td>{EventsTable.getEventUnique(this.props.data, "Funnel split-1day1495")}</td>
                </tr>
                {eventTotals}
                </tbody>
            </table>
        );
    }
}

export default EventsTable;
