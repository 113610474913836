import React from 'react';
import classes from './Footer.module.css';

const Footer = (props) => {
    return (
        <div className={classes.root}>
            <a href="https://jordanbelfort.com/terms-conditions/"  target="_blank" rel="noopener noreferrer">Terms & Conditions</a>
            <a href="https://jordanbelfort.com/privacy/"  target="_blank" rel="noopener noreferrer">Privacy Policy</a>
        </div>
    );
};

export default Footer;