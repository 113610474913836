import * as actionTypes from "./actionTypes";
import * as splitProfiles from "../../shared/splitProfiles";
import * as internal from "./analytics";

export const splitTestsLoaded = (data, splitChoices) => {
    return {
        type: actionTypes.SPLIT_TESTS_LOADED,
        data: data,
        splitChoices: splitChoices
    }
};

export const initSplitTests = () => {
    return dispatch => {
        const splitVars = localStorage.getItem('split');
        const splitVersion = localStorage.getItem('splitVersion');

        if (splitVars !== null && splitVersion !== null && splitVersion === splitProfiles.profileVersion) {
            const parsed = JSON.parse(splitVars);
            const splitChoices = {};

            splitProfiles.activeProfiles.forEach(profile => {
                const index = parsed[profile.name];
                splitChoices[profile.name] = profile.values[index].name;
                dispatch(internal.sendAnalytics("Funnel split-" + profile.values[index].name));
            });

            dispatch(splitTestsLoaded(parsed, splitChoices))
        } else {
            let data = {};
            const splitChoices = {};

            splitProfiles.activeProfiles.forEach(profile => {
                data[profile.name] = getSplitValue(profile);
                const index = data[profile.name];
                splitChoices[profile.name] = profile.values[index].name;
                dispatch(internal.sendAnalytics("Funnel split-" + profile.values[index].name));
            });

            localStorage.setItem('split', JSON.stringify(data));
            localStorage.setItem('splitVersion', splitProfiles.profileVersion);
            dispatch(splitTestsLoaded(data, splitChoices));
        }
    };
};

const getSplitValue = (profile) => {
    const random = Math.random();
    let result = 0;
    let found = false;
    profile.values.map(v => v.chance).reduce((a, x, i) => [...a, x + (a[i - 1] || 0)], []).forEach((v, i) => {
        if (v > random && !found) {
            result = i;
            found = true;
        }
    });
    return result;
};