import React, {Component} from 'react';
import * as actions from "../../store/actions";
import {connect} from "react-redux";
import classes from './OrderForm.module.css';
import PageSpinner from "../Spinner/PageSpinner";
import {toMoneyString} from "../../shared/aggregationUtils";
import CreditForm from "../ContactFormPage/ContactFormBlock/CreditForm/CreditForm";
import _ from "lodash";

class OrderForm extends Component {

    state = {
        brainTreeInstance: null,
        brainTreeReady: false,
        userId: null
    };

    componentDidMount() {
        if (this.props.match.params.id) {
            this.props.getOrderForm(this.props.match.params.id)
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.data === null && this.props.data !== null) {
            localStorage.setItem("userId", this.props.data.userId);
            this.setState({
                ...this.state,
                userId: this.props.data.userId
            })
        }
        if (!prevProps.buySuccess && this.props.buySuccess) {
            this.props.getOrderForm(this.props.match.params.id)
        }
    }

    setBrainTreeInstance(instance) {
        instance.on('paymentMethodRequestable', (event) => {
            this.setState({
                ...this.state,
                brainTreeReady: true
            })
        });
        this.setState({
            ...this.state,
            brainTreeInstance: instance,
            brainTreeReady: instance.isPaymentMethodRequestable()
        })
    }

    submit() {
        if (this.state.brainTreeInstance) {
            this.state.brainTreeInstance.requestPaymentMethod((err, payload) => {
                if (err) {
                    // Do something with the error
                } else {
                    this.submitNonce(payload.nonce)
                }
            })
        } else {
            console.warn("Instance not loaded");
        }
    }

    submitNonce(nonce) {
        this.props.buy(nonce, this.props.data.id)
    }

    render() {
        if (!this.props.data && !this.props.error) {
            return <PageSpinner/>
        }
        if (this.props.error && !this.props.data) {
            return <div className={classes.root}>
                Could not load order form
            </div>
        }
        if (this.props.data && this.props.data.state === "SUCCESS") {
            return <div className={classes.root}>
                Congratulations you have successfully bought {this.props.data.productName}!
            </div>
        }
        if (this.props.data && this.props.data.state !== "UNPROCESSED") {
            return <div className={classes.root}>
                This order form seems to be invalid or already paid
            </div>
        }
        return (
            <div className={classes.root}>
                {this.props.loading ? <PageSpinner/> : null}
                {this.props.error ? <p>{this.props.error}</p> : null}
                You are about to buy {this.props.data.productName} for {toMoneyString(this.props.data.price)}
                {this.state.userId ? <div className={classes.brainTreeWrapper}>
                    <CreditForm
                        brainTreeInstance={this.state.brainTreeInstance}
                        setBrainTreeInstance={(instance) => this.setBrainTreeInstance(instance)}
                        price={this.props.data.price / 100}
                        product={this.props.data.productName}
                    />
                </div> : null }
                <button className={classes.button} disabled={!this.state.brainTreeReady}
                        onClick={_.debounce(() => this.submit(), 300, {
                            'leading': true,
                            'trailing': false
                        })}>
                    <b>Yes</b>, I want this!
                </button>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state.orderForm.loading,
        error: state.orderForm.error,
        data: state.orderForm.data,
        buySuccess: state.orderForm.buySuccess
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getOrderForm: (id) => dispatch(actions.getOrderForm(id)),
        buy: (nonce, id) => dispatch(actions.setNonceAndBuyFromForm(nonce, id)),

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderForm);
