import React, {Component} from 'react';

class BundleFeatures extends Component {

    render() {
        return (
            <div>BundleFeatures</div>
        );
    }
}

export default BundleFeatures;
