export var SCRIPT_BUILDER_UPSELL = 'script-builder-upsell';
export var SCRIPT_BUILDER_DOWNSELL = 'script-builder-downsell';
export var SO_MANUAL = 'so-manual';
export var FAST_TRACK = 'fast-track';

export var STRAIGHT_LINE_PERSUASION = 'straight-line-persuasion';
export var STRAIGHT_LINE_PERSUASION_SUB = 'straight-line-persuasion-sub-monthly';

export var SUB_ANNUAL = 'sub-annual';
export var SUB_ANNUAL_7_8900 = 'sub-annual-7-8900';
export var SUB_ANNUAL_1_8900 = 'sub-annual-1-8900';

export var SUB_ANNUAL_3_9700 = 'sub-annual-3-9700'; /******this****/

export var SUB_ANNUAL_0_9700 = 'sub-annual-0-9700';
export var SUB_ANNUAL_0_23520 = 'sub-annual-0-23520';

export var SUB_MONTHLY = 'sub-monthly';
export var SUB_MONTHLY_7_995 = 'sub-monthly-7-995';
export var SUB_MONTHLY_7_1495 = 'sub-monthly-7-1495';
export var SUB_MONTHLY_1_1495 = 'sub-monthly-1-1495';
export var SUB_MONTHLY_3_1995 = 'sub-monthly-3-1995';

export var SUB_MONTHLY_0_1995 = 'sub-monthly-0-2995'; /******this****/

export var SUB_MONTHLY_0_4995 = 'sub-monthly-0-4995';

export var SUB_QUARTERLY_3_3375 = 'sub-quarterly-3-3375';
export var SUB_QUARTERLY_7_3375 = 'sub-quarterly-7-3375';
export var SUB_QUARTERLY_1_3375 = 'sub-quarterly-1-3375';
export var SUB_QUARTERLY_7_2250 = 'sub-quarterly-7-2250';

export var SUB_QUARTERLY_3_4200 = 'sub-quarterly-0-5700'; /******this****/

export var SUB_QUARTERLY_0_4200 = 'sub-quarterly-0-4200';
export var SUB_QUARTERLY_0_10290 = 'sub-quarterly-0-10290';

export var SLSC_CERT_FULL = "slp-certification";
export var SLM = "straight-line-marketing";
export var MANUAL_PRINT = "stratton-manual-hardcopy";


export const productPrices = {
    [SCRIPT_BUILDER_DOWNSELL]: 9700,
    [SCRIPT_BUILDER_UPSELL]: 19900,
    [SO_MANUAL]: 7900,
    [FAST_TRACK]: 9500,
    [STRAIGHT_LINE_PERSUASION]: 49700,
    [STRAIGHT_LINE_PERSUASION_SUB]: 33300,
    [SUB_ANNUAL]: 8900,
    [SUB_ANNUAL_7_8900]: 8900,
    [SUB_ANNUAL_1_8900]: 8900,
    [SUB_ANNUAL_3_9700]: 9700,
    [SUB_ANNUAL_0_9700]: 9700,
    [SUB_ANNUAL_0_23520]: 10290,
    [SUB_MONTHLY]: 1495,
    [SUB_MONTHLY_7_995]: 995,
    [SUB_MONTHLY_7_1495]: 1495,
    [SUB_MONTHLY_1_1495]: 1495,
    [SUB_MONTHLY_3_1995]: 1995,
    [SUB_MONTHLY_0_1995]: 1995,
    [SUB_MONTHLY_0_4995]: 4995,
    [SUB_QUARTERLY_3_3375]: 3375,
    [SUB_QUARTERLY_7_3375]: 3375,
    [SUB_QUARTERLY_1_3375]: 3375,
    [SUB_QUARTERLY_7_2250]: 2250,
    [SUB_QUARTERLY_3_4200]: 4200,
    [SUB_QUARTERLY_0_4200]: 4200,
    [SUB_QUARTERLY_0_10290]: 10290,

    [SLSC_CERT_FULL]: 499500,
    [SLM]: 30000,
    [MANUAL_PRINT]: 50000,


};

export const productSavings = {
    [SCRIPT_BUILDER_DOWNSELL]: 59600,
    [SCRIPT_BUILDER_UPSELL]: 40000,
    [SO_MANUAL]: 26300,
    [FAST_TRACK]: 32300,
    [STRAIGHT_LINE_PERSUASION]: 332300,
    [STRAIGHT_LINE_PERSUASION_SUB]: 100000,
    [SUB_ANNUAL]: 9040,
    [SUB_ANNUAL_7_8900]: 9040,
    [SUB_MONTHLY]: 0,
    [SUB_MONTHLY_7_995]: 0,
    [SUB_MONTHLY_7_1495]: 0,
    [SUB_QUARTERLY_3_3375]: 4440,
    [SUB_QUARTERLY_7_3375]: 4440,
    [SUB_QUARTERLY_7_2250]: 0,

    [SLSC_CERT_FULL]: 499500,
    [SLM]: 99900,
    [MANUAL_PRINT]: 50000,
};

export const subscriptionProducts = [
    SUB_ANNUAL,
    SUB_ANNUAL_7_8900,
    SUB_ANNUAL_1_8900,
    SUB_ANNUAL_3_9700,
    SUB_ANNUAL_0_9700,
    SUB_ANNUAL_0_23520,
    SUB_MONTHLY,
    SUB_MONTHLY_7_995,
    SUB_MONTHLY_7_1495,
    SUB_MONTHLY_1_1495,
    SUB_MONTHLY_3_1995,
    SUB_MONTHLY_0_1995,
    SUB_MONTHLY_0_4995,
    SUB_QUARTERLY_3_3375,
    SUB_QUARTERLY_7_3375,
    SUB_QUARTERLY_1_3375,
    SUB_QUARTERLY_7_2250,
    SUB_QUARTERLY_3_4200,
    SUB_QUARTERLY_0_4200,
    SUB_QUARTERLY_0_10290
];
