import React, {Component} from 'react';
import EventRow from "./EventRow";
import classes from './EventList.module.css';
import tableClasses from '../Table.module.css';
import {FaCheck} from "react-icons/fa";

class EventList extends Component {

    state = {
        activeRow: null,
        emailQuery: "",
        eventFilter: "all",
        sourceFilter: "all",
        platformFilter: "all",
        hasCartFilter: false
    };

    setActiveRow(row) {
        this.setState({
            ...this.state,
            activeRow: row
        })
    }

    handleQuery(event) {
        const val = event.target.value;

        this.setState({
            ...this.state,
            emailQuery: val
        })
    }

    handleEventFilter(event) {
        this.setState({
            ...this.state,
            eventFilter: event.target.value
        })
    }

    handleSourceFilter(event) {
        this.setState({
            ...this.state,
            sourceFilter: event.target.value
        })
    }

    handlePlatformFilter(event) {
        this.setState({
            ...this.state,
            platformFilter: event.target.value
        })
    }

    handleHasCartChange() {
        this.setState({
            ...this.state,
            hasCartFilter: !this.state.hasCartFilter
        })
    }

    render() {
        let eventsList = null;
        let eventTags = ["all"];
        let sourceTags = ["all"];
        let platformTags = ["all"];
        if (this.props.data) {
            let data = this.props.data;
            if (this.state.emailQuery !== "") {
                data = data.filter(v => v.email.includes(this.state.emailQuery));
            }
            if (this.state.eventFilter !== "all") {
                data = data.filter(v => v.pages.includes(this.state.eventFilter));
            }
            if (this.state.sourceFilter !== "all") {
                data = data.filter(v => v.sources.includes(this.state.sourceFilter));
            }
            if (this.state.hasCartFilter) {
                data = data.filter(v => v.products.length > 0);
            }
            eventsList = data.map(user =>
                <EventRow
                    key={user.id}
                    active={this.state.activeRow === user.id}
                    data={user}
                    clicked={() => this.setActiveRow(this.state.activeRow === user.id ? null : user.id)}
                />
            );
            data.flatMap(user => user.pages).filter((v, i, a) => a.indexOf(v) === i).forEach(v => eventTags.push(v));
            data.flatMap(user => user.sources).filter((v, i, a) => a.indexOf(v) === i).forEach(v => sourceTags.push(v));
            data.map(user => user.platform).filter((v, i, a) => a.indexOf(v) === i).forEach(v => platformTags.push(v));
        }
        const eventOptions = eventTags.map(v => <option value={v} key={v}>{v}</option>);
        const sourceOptions = sourceTags.map(v => <option value={v} key={v}>{v}</option>);
        const platformOptions = platformTags.map(v => <option value={v} key={v}>{v}</option>);
        return (
            <div>
                <ul className={classes.root}>
                    <div className={classes.filters}>
                        <label className={classes.checkLabel}> Email:
                            <input className={classes.queryInput} type="text" value={this.state.emailQuery}
                                   onChange={(event) => this.handleQuery(event)} placeholder="Email"/>
                        </label>
                        <label className={classes.checkLabel}> Event:
                            <select className={classes.filter} value={this.state.eventFilter}
                                    onChange={(event) => this.handleEventFilter(event)}>
                                {eventOptions}
                            </select>
                        </label>
                        <label className={classes.checkLabel}> Platform:
                            <select className={classes.filter} value={this.state.platformFilter}
                                    onChange={(event) => this.handlePlatformFilter(event)}>
                                {platformOptions}
                            </select>
                        </label>
                        <label className={classes.checkLabel}> Source:
                            <select className={classes.filter} value={this.state.sourceFilter}
                                    onChange={(event) => this.handleSourceFilter(event)}>
                                {sourceOptions}
                            </select>
                        </label>
                        <div className={classes.checkLabel}>Has Cart Value:</div>
                        <div className={classes.checkBox}
                             onClick={() => this.handleHasCartChange()}>{this.state.hasCartFilter ?
                            <FaCheck className={classes.check}/> : null}</div>
                    </div>
                    <table className={tableClasses.table}>
                        <tbody>
                        <tr className={tableClasses.headerRow}>
                            <th>Ip</th>
                            <th>Email</th>
                            <th>Platform</th>
                            <th>Sources</th>
                            <th>Total Cart Value</th>
                            <th>Last Activity</th>
                        </tr>
                        {eventsList}
                        </tbody>
                    </table>

                </ul>
            </div>
        );
    }
}

export default EventList;
