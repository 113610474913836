import React, {Component} from 'react';
import classes from '../Table.module.css';
import {
    getAverageOrderValue,
    getAverageValuePerClick,
    getTotalOrderValue,
    toMoneyString
} from "../../../shared/aggregationUtils";
import Aux from "../../../hoc/Aux";

class PlatformTable extends Component {

    static getPlatformUnique(data, platformTag) {
        return data.filter(val => val.platform === platformTag).length;
    }

    render() {
        const platforms = this.props.data.map(v => v.platform).filter((v, i, a) => a.indexOf(v) === i);
        const platformsFilter = e => { return val => {return val.platform === e}};
        const platformTotals = platforms.map(e =>
            <tr className={classes.row} key={e}>
                <th>{e}</th>
                <td>{PlatformTable.getPlatformUnique(this.props.data, e)}</td>
                <td>{toMoneyString(getAverageValuePerClick(this.props.data, platformsFilter(e)))}</td>
                <td>{toMoneyString(getTotalOrderValue(this.props.data, platformsFilter(e)))}</td>
                <td>{toMoneyString(getAverageOrderValue(this.props.data, platformsFilter(e)))}</td>
            </tr>);

        return (
            <Aux>
                <tr className={classes.headerRow}>
                    <th>Platform</th>
                    <th>Unique</th>
                    <th>AVC</th>
                    <th>TOV</th>
                    <th>AOV</th>
                </tr>
                {platformTotals}
            </Aux>
        );
    }
}

export default PlatformTable;
