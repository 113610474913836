import * as actionTypes from '../actions/actionTypes';
import {reportedProfiles} from "../../shared/splitProfiles";

const initialState = {
    superProps: {}
};

const setSplitProperties = (state, action) => {
    const reportedProfileNames = reportedProfiles.map(p => p.name);

    const splitValues = {};
    reportedProfileNames.forEach(pn => splitValues[pn] = action.splitChoices[pn]);

    window.mixpanel.register(splitValues);
    return {
        superProps: {
            ...state.superProps,
            ...splitValues
        }
    }
};

const findSource = (urlParams) => {
    const sourceText = urlParams.get('utm_source');
    if (!sourceText) return 'Organic';
    const sourceSplit = sourceText.split('_');

    if (sourceText[0] === 'D') {
        if (sourceText[2] === 'l') {
            return 'LinkedIn';
        }
        return 'Youtube';
    } else if (sourceSplit.length > 1) {
        return sourceSplit[0];
    } else {
        return urlParams.get('utm_source');
    }
};

const setInitialProperties = (state, action) => {
    const url = new URLSearchParams(window.location.search);
    const source = findSource(url);
    localStorage.setItem("source", source);

    window.mixpanel.register({
        "Funnel": "The Wolf Network (70% Discount) Snapchat Funnel",
        "Source": source,
    });
    return {
        superProps: {
            ...state.superProps,
            // "Base Referrer": baseReferrer
        }
    }
};

const setAffiliateProperties = (state, action) => {
    window.mixpanel.register({
        "Affiliate Id": action.id,
        "Affiliate System": action.system
    });
    return {
        superProps: {
            ...state.superProps,
            "Affiliate Id": action.id,
            "Affiliate System": action.system
        }
    }
};

const setSubIdProperty = (state, action) => {
    const source = localStorage.getItem("source");
    window.mixpanel.register({
        "Funnel": "The Wolf Network (70% off) Snapchat Funnel",
        "Source": source
    });
    return {
        superProps: {
            ...state.superProps,
            "Funnel": "The Wolf Network (70% off) Snapchat Funnel",
            "Source": source
        }
    }
};

const sendEvent = (state, action) => {
    if (process.env.NODE_ENV === 'production') {
        window.mixpanel.track(action.tag);
    } else {
        console.log("\"Sending\" analytics", action.tag)
    }
    return state;
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SPLIT_TESTS_LOADED:
            return setSplitProperties(state, action);
        case actionTypes.AFFILIATE_LOADED:
            return setAffiliateProperties(state, action);
        case actionTypes.SET_CONTACT_SOURCE:
            return setSubIdProperty(state, action);
        case actionTypes.LOAD_PAGE:
            return setInitialProperties(state, action);
        case actionTypes.SEND_EVENT:
            return sendEvent(state, action);
        default:
            return state;
    }
};

export default reducer;
