import React, {Component} from 'react';
import classes from './ContactForm.module.css';
import {connect} from "react-redux";
import * as actions from "../../../../store/actions";
import Spinner from "../../../Spinner/Spinner";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import _ from "lodash";
import {NUMVERIFY_API_KEY} from "../../../../config";
import {CHECKOUT_PAGE, SUBMIT_CONTACT} from '../../../../shared/events';
import ReactPixel from "react-facebook-pixel";

class ContactForm extends Component {

    state = {

        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        firstNameError: null,
        lastNameError: null,
        emailError: null,
        phoneError: null
    };

    componentDidMount() {
        this.props.sendEvent(CHECKOUT_PAGE);

        if (process.env.NODE_ENV !== 'production') {

            this.setState({
                ...this.state,
                firstName: 'test',
                lastName: 'test',
                /*
                                email: (Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)) + "@gmail.com",
                */
                email: "testuserforjbfunnels@jb.com",
                phone: '33',
            })
        }
    }

    handleChangeFirstName(event) {
        let val = event.target.value;
        const error = ContactForm.validateFirstName(val);
        this.setState({
            ...this.state,
            firstName: val,
            firstNameError: error
        })
    }

    handleChangeLastName(event) {
        let val = event.target.value;
        const error = ContactForm.validateLastName(val);
        this.setState({
            ...this.state,
            lastName: val,
            lastNameError: error
        })
    }

    handleChangeEmail(event) {
        let val = event.target.value;
        const error = ContactForm.validateEmail(val);
        this.setState({
            ...this.state,
            email: val,
            emailError: error
        })
    }

    static validateFirstName(val) {
        if (val.length === 0) {
            return 'First name is required'
        }
        return null
    }

    static validateLastName(val) {
        if (val.length === 0) {
            return 'Last name is required'
        }
        return null
    }

    static validateEmail(val) {
        if (val.length <= 0) {
            return 'email is required'
        }
        return null
    }

    verifyPhone(number) {
        if (process.env.NODE_ENV !== 'production') {
            this.handleRestOfSubmit(null)
        }
        fetch(`https://apilayer.net/api/validate?access_key=${NUMVERIFY_API_KEY}&number=${number}`)
            .then(res => res.json())
            .then(data => data.valid ? this.handleRestOfSubmit(null) : this.handleRestOfSubmit("Phone number is not valid.."))
            .catch(err => {
                console.warn(err);
                this.handleRestOfSubmit(null);
            });
    }

    submit(e) {
        e.preventDefault();
        if (process.env.NODE_ENV !== 'production') {
            this.verifyPhone(this.state.phone);
        } else {
            if (this.state.phone === undefined || this.state.phone === null || this.state.phone === '') {
                this.handleRestOfSubmit('Phone number is required');
            } else if (this.state.phone) {
                this.verifyPhone(this.state.phone);
            }
        }


    }

    handleRestOfSubmit(phoneError) {
        const firstNameError = ContactForm.validateFirstName(this.state.firstName);
        const lastNameError = ContactForm.validateLastName(this.state.lastName);
        const emailError = ContactForm.validateEmail(this.state.email);

        if (lastNameError != null || firstNameError != null || emailError != null || phoneError != null) {
            this.setState({
                ...this.state,
                firstNameError: firstNameError,
                lastNameError: lastNameError,
                emailError: emailError,
                phoneError: phoneError
            });
            return;
        }
        const allowExists = this.props.allowExists === true;
        this.props.sendEvent(SUBMIT_CONTACT);



        localStorage.setItem("contactName", this.state.firstName+" "+ this.state.lastName);
        localStorage.setItem("contactEmail", this.state.email);
        localStorage.setItem("contactPhone", this.state.phone);

        if (process.env.NODE_ENV !== 'production') {

            this.props.setContact({
                firstName: "test",
                lastName: "test",
/*
                email: (Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)) + "@gmail.com",
*/
                email: "testuserforjbfunnels@jb.com",
                phone: 33,
                source: this.props.source,
                allowExists
            });

        } else {
            this.props.setContact({
                firstName: this.state.firstName,
                lastName: this.state.lastName,
                email: this.state.email,
                phone: this.state.phone,
                source: this.props.source,
                allowExists: true
            });
        }


        window.fbq('trackCustom', 'Submitted Contact Info');
        ReactPixel.trackCustom('SubmittedContact', {content_name: 'wolfnetwork70off',});
    }

    render() {
        /*      if (this.state.phone){
                  console.log("this phone state length is", this.state.phone.length);
              }*/

        if (this.props.loading) {
            return <div className={classes.root}>
                <div className={classes.spinnerBox}>
                    <Spinner/>
                </div>
            </div>
        }
        let country = localStorage.getItem("country");
        if (country === null) {
            country = "ZZ";
        }
        return (

            <div className={classes.root}>
                <div className={classes.submitStepOneContainer}>
                    {!this.state.phone || this.state.phone.length < 7 ?


                        <div className={classes.submit}>
                            <div className={classes.stepOne}>
                                Step #1
                            </div>
                        </div>

                        :

                        this.state.phone && this.state.phone.length >= 7 ?
                            <div className={classes.submitOff}>
                                <div className={classes.stepOne}>
                                    Step #1
                                </div>
                            </div>
                            :
                            !this.state.phone ?
                                <div className={classes.submit}>
                                    <div className={classes.stepOne}>
                                        Step #1
                                    </div>
                                </div>
                                : null
                    }


                </div>


                <form className={classes.root}>
                    {this.props.error ? <p className={classes.formError}>{this.props.error}</p> : null}
                    <input className={classes.input + (this.state.firstNameError ? ' ' + classes.inputError : '')}
                           type='text' value={this.state.firstName}
                           onChange={(event) => this.handleChangeFirstName(event)}
                           placeholder='First Name...'/>
                    {this.state.firstNameError ? <p className={classes.error}>{this.state.firstNameError}</p> : null}
                    <input className={classes.input + (this.state.lastNameError ? ' ' + classes.inputError : '')}
                           type='text' value={this.state.lastName}
                           onChange={(event) => this.handleChangeLastName(event)}
                           placeholder='Last Name...'/>
                    {this.state.lastNameError ? <p className={classes.error}>{this.state.lastNameError}</p> : null}
                    <input className={classes.input + (this.state.emailError ? ' ' + classes.inputError : '')}
                           type='email'
                           onChange={(event) => this.handleChangeEmail(event)}
                           placeholder='Email Address...'/>
                    {this.state.emailError ? <p className={classes.error}>{this.state.emailError}</p> : null}
                    <PhoneInput
                        placeholder="Phone Number..."
                        className={classes.phoneInput}
                        value={this.state.phone}
                        country={country}
                        onChange={phone => this.setState({phone})}/>
                    {this.state.phoneError ? <p className={classes.error}>{this.state.phoneError}</p> : null}

                    {this.state.phone && this.state.phone.length >= 7 ?
                        <button className={classes.submit + " " + classes.desktop}
                                onClick={_.debounce((e) => this.submit(e), 300, {
                                    'leading': true,
                                    'trailing': false
                                })}>Go To Step #2
                        </button>
                        :

                        <button className={classes.submitOff + " " + classes.desktop}
                                onClick={_.debounce((e) => this.submit(e), 300, {
                                    'leading': true,
                                    'trailing': false
                                })}>Go To Step #2
                        </button>
                    }
                    {/*  <button className={classes.submit + " " + classes.desktop}
                        onClick={_.debounce((e) => this.submit(e), 300, {
                            'leading': true,
                            'trailing': false
                        })}>Go To Step #2
                </button>*/}


                    {this.state.phone && this.state.phone.length >= 7 ?
                        <button className={classes.submit + " " + classes.phone}
                                onClick={_.debounce((e) => this.submit(e), 300, {
                                    'leading': true,
                                    'trailing': false
                                })}>Go To Step #2
                        </button>
                        :

                        <button className={classes.submitOff + " " + classes.phone}
                                onClick={_.debounce((e) => this.submit(e), 300, {
                                    'leading': true,
                                    'trailing': false
                                })}>Go To Step #2
                        </button>
                    }


                    <p className={classes.subtext}>We Respect Your Privacy & Information</p>
                    <p className={classes.subtext}>By clicking submit I consent for Global Motivations Inc to call me,
                        including possibly using automated technology and recurring texts, with offers and other info,
                        at
                        the number I entered. Consent is not a condition of purchase. Text STOP to cancel and HELP for
                        help.</p>
                    <div className={classes.legalLinks}>
                        <a className={classes.legalLink} href="https://jordanbelfort.com/terms-conditions/"
                           target="_blank"
                           rel="noopener noreferrer">Terms & Conditions</a>
                        <a className={classes.legalLink} href="https://jordanbelfort.com/privacy/" target="_blank"
                           rel="noopener noreferrer">Privacy Policy</a>
                    </div>
                </form>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state.contact.loading,
        error: state.contact.error,
        source: state.contact.source
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setContact: (contact) => dispatch(actions.setContactInfo(contact)),
        sendEvent: (eventTag) => {
            dispatch(actions.sendAnalytics(eventTag));
            dispatch(actions.sendEvent(eventTag))
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactForm);
