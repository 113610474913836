import React from 'react';
import {connect} from 'react-redux';
import {Link} from "react-router-dom";
import styles from './Header.module.css';
import logo from '../../../assets/images/JBLogo.png';
import HamburgerMenu from './HamburgerMenu/HamburgerMenu';

class Header extends React.Component {
    state = {
        currentPage: '',
        prevScrollpos: window.pageYOffset,
        visible: true
    };

    componentDidMount() {
        window.addEventListener("scroll", this.handleScroll);

        /*    if (this.props.cart && this.props.cart.length < 1) {
              //  this.props.getCartItems();
            }*/
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.handleScroll);
    }

    handleScroll = () => {
        const {prevScrollpos} = this.state;

        const currentScrollPos = window.pageYOffset;
        const visible = prevScrollpos > currentScrollPos;

        this.setState({
            prevScrollpos: currentScrollPos,
            visible
        });
    };

    render() {
        const headerItems = [
            {
                title: 'What You Massively Increase',
                url: 'scroll_here',
                type: 'href'
            },
            {
                title: 'Onboarding Call',
                url: 'https://jordanbelfort.com/about/',
                type: 'a'
            },
            {
                title: 'Testimonials',
                url: 'https://jordanbelfort.com/testimonials/',
                type: 'a'
            },
            {
                title: 'About The Program',
                url: '/',
                type: 'Link'
            }
        ];

        const headerButtons = headerItems.map(item => {
            let currentStyle = styles.headerItems;
            if (item.title === this.state.currentPage) {
                currentStyle += ' ' + styles.active;
            }

            if (item.type === 'a') {
                return (
                    <a
                        href={item.url}
                        className={currentStyle}
                        onClick={() => this.setState({currentPage: item.title})}
                        key={item.title}
                    >
                        {item.title}
                    </a>
                );
            } else {
                return (
                    <Link
                        className={currentStyle}
                        onClick={() => this.setState({currentPage: item.title})}
                        to={item.url}
                        key={item.title}
                    >
                        {item.title}
                    </Link>
                );
            }
        });

        return (
            <div className={ `${styles.header}  ${styles.navbar} ${this.state.visible ? null : styles.navbarHidden}`}>


                <div className={`${styles.container} ${styles.cartCount}`}>
                    <HamburgerMenu links={headerButtons}/>
                </div>


                <div className={`${styles.container} ${styles.heading}`}>
                    <a className={styles.headerLink} href="https://jordanbelfort.com">
                        <img src={logo} alt="logo" className={styles.logo}/>
                    </a>
                </div>


                <div className={styles.urlContainer}>
                    {headerButtons}
                </div>


            </div>
        );
    }
}

const mapStateToProps = state => ({/*
    cart: state.cartReducer.cart,
    location: state.locationReducer.location*/
});

const mapDispatchToProps = dispatch => ({
    // getCartItems: () => dispatch(getCartItems())
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
