import React from 'react';

class EnrollNow extends React.Component {
    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return false;
    }

    render() {
        return (
            <img
                height="1"
                width="1"
                style ={{display: 'none'}}
                alt=""
                src="https://px.ads.linkedin.com/collect/?pid=594002&conversionId=1222458&fmt=gif"
            />
        );
    }
}

export default EnrollNow;