import React, {Component} from 'react';
import classes from './ContactFormBlock.module.css';
import {connect} from "react-redux";
import CreditCardForm from "./CreditCardForm/CreditCardForm";
import ContactForm from "./ContactForm/ContactForm";

class ContactFormBlock extends Component {

    render() {
        const hasTrial = this.props.split.days > 0;
        const title = hasTrial ? 'Join Right Now!' : 'Enroll Now!';
        return (
            <div className={classes.root}>
                <div className={classes.phoneProgress}>
                    <h2>{title}</h2>
                </div>
                <div className={classes.progressRow}>
                    <div className={classes.progressBlock}>
                        {/*  <div className={classes.stepNumber}>1</div>
                        <div className={classes.stepText}>
                             <p className={classes.stepTitle + (this.props.contactSuccess ? '' : ' ' + classes.stepTitleActive)}>
                                 { hasTrial ?  "Your Info" : "30 DAY MONEY BACK GUARANTEE" }
                            </p>
                            <p className={classes.stepSubtitle}>Sign Up Now</p>
                        </div>*/}
                        {this.props.contactSuccess ?
                            <div className={classes.quickTwoStep}>Final Step: Choose Your Program</div>

                            :
                            <div className={classes.quickTwoStep}>Quick & Easy 2-Step Checkout Process</div>
                        }



                    </div>
                    {/*  <div className={classes.progressBlock}>
                        <div className={classes.stepNumber}>2</div>
                        <div className={classes.stepText}>
                            <p className={classes.stepTitle + (this.props.contactSuccess ? ' ' + classes.stepTitleActive : '')}>
                                Payment Info
                            </p>
                            <p className={classes.stepSubtitle}>Start Now</p>
                        </div>
                    </div>*/}

                </div>
                {/*  <div className={classes.submitOne}>
                <div className={classes.stepOne}>
                    Step #1
                </div>
                </div>*/}
                {this.props.contactSuccess ?
                    <CreditCardForm split={this.props.split}/> : <ContactForm/>}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        contactSuccess: state.pages.contactDone
    };
};

const mapDispatchToProps = dispatch => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactFormBlock);
