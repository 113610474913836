import React from 'react';
import classes from './About.module.css';
import wowBook from '../../../assets/images/wow_book.jpg';
import wotwBook from '../../../assets/images/wotw_book.jpg';
import ctwowBook from '../../../assets/images/ctw_book.jpg';
import salesTrainer from '../../../assets/images/sales-trainer.png';
import motivation from '../../../assets/images/motivation-speaking.jpg';

const About = (props) => {
    const hasTrial = props.split.days > 0;
    const buttonTitle = hasTrial ? 'Join Right Now' : 'Enroll Now';
    return (
        <div className={classes.root}>
            <div className={classes.subRoot}>
                <div className={classes.left}>
                    <div className={classes.imageBox}>
                    </div>
                </div>
                <div className={classes.right}>
                    <h2 className={classes.title}>About The Program</h2>
                    <p className={classes.body}>
                        If you’re like most of our members you already know that the ability to “close the deal” is the
                        most important skill you can master when it comes to achieving success. The problem is that you
                        don’t always have the time to stay at the top of your sales game.
                    </p>
                    <p className={classes.body}>

                        With this groundbreaking program, Jordan has turned his world-renown Straight Line Persuasion
                        System into a series of high-impact daily, weekly, and monthly trainings— leveraging the latest advances
                        in interactive video to ensure that you quickly get to the top of your game and remain there.

                    </p>
                    <button className={classes.button} onClick={() => props.buttonClicked()}>
                        <h3>{buttonTitle}</h3>
                        <p>Claim Your Free Bonuses + A One-Time 70% Discount On Every Product In Our Online Store</p>
                    </button>
                </div>
            </div>
            <div className={classes.mobileSubRoot}>
                <h2>WHO IS JORDAN BELFORT?</h2>
                <h3>Best Selling Author</h3>
                <div className={classes.books}>
                    <div className={classes.book + " " + classes.bookLeft}><img className={classes.image}
                                                                                alt="Wolf of Wall Street Book"
                                                                                src={wowBook}/></div>
                    <div className={classes.book}><img className={classes.image}
                                                       alt="Catching the Wolf Of Wall Street Book" src={ctwowBook}/>
                    </div>
                    <div className={classes.book + " " + classes.bookRight}><img className={classes.image}
                                                                                 alt="Way of the Wolf Book"
                                                                                 src={wotwBook}/></div>
                </div>
                <h3 className={classes.mobileImageTitle}>#1 Sales Trainer in the World</h3>
                <div className={classes.mobileImageBox}>
                    <img className={classes.image} alt="#1 sales trainer" src={salesTrainer}/>
                </div>
                <h3 className={classes.mobileImageTitle}>Worldwide Motivational Speaker</h3>
                <div className={classes.mobileImageBox}>
                    <img className={classes.image} alt="motivational speaker" src={motivation}/>
                </div>

            </div>
        </div>
    )
};

export default About;
