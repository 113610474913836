import React, {Component} from 'react';
import classes from './Upsell.module.css';
import scriptImage from '../../assets/images/scriptThumb.png';
import leftSb from '../../assets/images/leftSb.jpg';
import rightSb from '../../assets/images/sbright.jpg';
import * as actions from "../../store/actions";
import {connect} from "react-redux";
import * as productTags from '../../shared/products';
import {SCRIPT_BUILDER_UPSELL} from '../../shared/products';
import PageSpinner from "../Spinner/PageSpinner";
import CreditForm from "../ContactFormPage/ContactFormBlock/CreditForm/CreditForm";
import VimeoPlayer from "../Vimeo/VimeoPlayer";
import upsellBannerAlt from "../../assets/images/sbup.jpg";
import _ from "lodash";
import CompleteOrder from "../LinkedIn/CompleteOrder";
import {SKIP_UPSELL, UPSELL, UPSELL_PAGE} from '../../shared/events';

class Upsell extends Component {

    state = {
        brainTreeInstance: null,
        brainTreeReady: false
    };

    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.sendEvent(UPSELL_PAGE);
    }

    setBrainTreeInstance(instance) {
        instance.on('paymentMethodRequestable', (event) => {
            this.setState({
                ...this.state,
                brainTreeReady: true
            })
        });
        if (this.props.paymentMethod === "google" || this.props.paymentMethod === "venmo") {
            this.setState({
                ...this.state,
                brainTreeInstance: instance,
                brainTreeReady: true
            })

        } else {
            this.setState({
                ...this.state,
                brainTreeInstance: instance,
                brainTreeReady: instance.isPaymentMethodRequestable()
            })
        }
    }

    skipProduct() {
        this.props.sendEvent(SKIP_UPSELL);
        this.props.skipScriptBuilder();
    }

    submit() {


        this.props.sendEvent(UPSELL);

        if (this.props.paymentMethod === "google" || this.props.paymentMethod === "venmo") {
            this.props.buy([SCRIPT_BUILDER_UPSELL], this.props.transactionProperties)
        } else {
            if (this.state.brainTreeInstance) {
                this.state.brainTreeInstance.requestPaymentMethod((err, payload) => {
                    if (err) {
                        console.log(err);
                    } else {
                        this.submitNonce(payload.nonce)
                    }
                })
            } else {
                console.warn("Instance not loaded");
            }
        }
    }

    submitNonce(nonce) {
        this.props.setNonceAndBuy(nonce, [SCRIPT_BUILDER_UPSELL], this.props.transactionProperties)
    }

    render() {
        console.log(this.props.paymentMethod);
        return (
            <div className={classes.root}>
                <CompleteOrder/>
                {/*<ConversionPixel memberId={this.props.memberId}/>*/}
                {this.props.loading ? <PageSpinner/> : null}
                {this.props.error ? <div className={classes.error}>{this.props.error}</div> : null}
                <h1 className={classes.title}><b>WAIT!</b> YOUR ORDER IS NOT COMPLETE!</h1>



                <div className={classes.pageContainer}>

                    <div className={classes.imageBoxVert}>
                        <img className={classes.images} height={600} src={leftSb} alt='script'/>
                    </div>

                <div className={classes.infoBlock}>
                    <div className={classes.left}>
                        <div className={classes.imageBox}>
                            <img className={classes.image} src={scriptImage} alt='script'/>
                        </div>
                        <p>Gives you the ability to write, practice, and deliver effective sales scripts. This is one of
                            the cornerstones of the Straight Line Persuasion System. <b>DRAMATICALLY INCREASE YOUR
                                CLOSING RATE TODAY.</b>
                        </p>
                        <div className={classes.brainTreeWrapper}>
                            <CreditForm
                                brainTreeInstance={this.state.brainTreeInstance}
                                setBrainTreeInstance={(instance) => this.setBrainTreeInstance(instance)}
                                price={199}
                                product="Script Builder"
                            />
                        </div>
                    </div>
                    <div className={classes.videoBox}>
                        <div className={classes.bannerBox}>
                            <img className={classes.banner} src={upsellBannerAlt} alt="Save 400 dollars"/>
                        </div>
                        <VimeoPlayer
                            width={775}
                            height={463}
                            threshold={1100}
                            multiplier={0.85}
                            playing
                            url="https://player.vimeo.com/video/386622417"
                        />
                        <h2 className={classes.videoTitle}>The World's Most Powerful Script Building System</h2>

                        <p className={classes.regularPrice}>REGULAR PRICE: <span> 599</span></p>
                        <p className={classes.offerText}>ONE-TIME 70% DISCOUNT <br/>FOR BRAND NEW MEMBERS:</p>
                        <p className={classes.offerPrice}>Only $179</p>

                        <div className={classes.buttonRow}>
                            <div className={classes.buttonWrapper} onClick={() => this.skipProduct()}>
                                <button className={classes.noButton}>
                                    <b>No</b>, I don't want this.
                                </button>
                            </div>
                            <div className={classes.buttonWrapper}>
                                <button className={classes.yesButton} disabled={!this.state.brainTreeReady}
                                        onClick={_.debounce(() => this.submit(), 300, {
                                            'leading': true,
                                            'trailing': false
                                        })}>
                                    <b>Yes</b>, I want this!
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                    <div className={classes.imageBoxVert}>
                        <img className={classes.images} height={600} src={rightSb} alt='script'/>
                    </div>

                </div>
                <div className={classes.footerPlaceholder}/>

            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state.braintree.loading,
        error: state.braintree.error,
        memberId: state.pages.affiliateMemberId,
        transactionProperties: state.mixPanel.superProps,
        paymentMethod: state.braintree.paymentMethod
    };
};

const mapDispatchToProps = dispatch => {
    return {
        skipScriptBuilder: () => dispatch(actions.addProductSkip(productTags.SCRIPT_BUILDER_UPSELL)),
        sendEvent: (eventTag) => {
            dispatch(actions.sendAnalytics(eventTag));
            dispatch(actions.sendEvent(eventTag))
        },
        setNonceAndBuy: (nonce, products, transactionProperties) => dispatch(actions.setNonceAndBuy(nonce, products, transactionProperties)),
        buy: (products, transactionProperties) => dispatch(actions.buyProducts(products, transactionProperties))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Upsell);
