import React, {Component} from 'react';
import classes from './Leo.module.css'
import {FaLockOpen} from "react-icons/fa";
import Carousel from "../../Carousel/Carousel";

class Leo extends Component {

    render() {
        const hasTrial = this.props.split.days > 0;
        const buttonTitle = hasTrial ? 'Join Right Now' : 'Enroll Now';
        return (
            <div className={classes.root}>
                <div className={classes.subRoot}>
                    <div className={classes.left}>
                        <h2 className={classes.title}>What Leonardo DiCaprio and Others Are Saying About Jordan's Trainings</h2>
                        <button className={classes.button + " " + classes.nonMobile} onClick={() => this.props.buttonClicked()}>
                            <h3><FaLockOpen/>{buttonTitle}</h3>
                            <p>Claim Your Free Bonuses + A One-Time 70% Discount On Every Product In Our Online Store</p>
                        </button>
                    </div>
                    <div className={classes.right}>
                        <Carousel
                            data={[
                                {type: 'video', url: "https://player.vimeo.com/video/301707630"},
                                {
                                    type: 'text',
                                    text: "Wow! Jordan Belfort Thank you so much for today's motivation. Somehow, your authentic desire to be of service bathes every message with a power that is truly contagious. Right place, right time and I appreciate it.",
                                    subText: "-Michelle Oliver"
                                },
                                {type: 'video', url: "https://vimeo.com/user17087177/t3"},
                                {
                                    type: 'text',
                                    text: "I wasn't worried for 1 second about the 300 I spent on these videos. I am watching them all beginning to end. Absolutely love the content and I am becoming a better salesman one video at a time! Thank you, Jordan for making a real webinar/training...finally advice worth my fucking money. Keep it up!",
                                    subText: "-Ben T."
                                },
                                {type: 'video', url: "https://vimeo.com/user17087177/t2"},
                                {
                                    type: 'text',
                                    text: "- This is lit, it's like Facebook but for Hustlers.\n\n- FACE...what?? Since the new version launched I'm spending more time here on every other platform.",
                                    subText: "-Nolan Moore & Christian Fentrop"
                                },
                                {type: 'video', url: "https://vimeo.com/user17087177/ab"},
                                {
                                    type: 'text',
                                    text: "This is really cool, right? It really is like a facebook for like-minded KILLAS!! It's pretty cool to see how similar all different industries are when it comes down to SELLING & what us salespeople go through on a daily basis.",
                                    subText: "-Justin Brain"
                                },
                                {type: 'video', url: "https://vimeo.com/user17087177/t4"},
                                {
                                    type: 'text',
                                    text: "I F'n love this page!! I'm very happy to be a follower of Jordan. He is the top 2-3 people who influence me in life.",
                                    subText: "-Mariusz Pawlukowiec"
                                },
                                {type: 'video', url: "https://vimeo.com/user17087177/t5"},
                                {
                                    type: 'text',
                                    text: "It's changed my life! It started it retail sales, I went from $800 to 2k by the afternoon after implementing what you've taught. Now I'm in phone sales and thanks to your teachings, I'm second on the leaderboard! (Only reason I'm not first is because I'm not putting as much time as the first guy) lol. Thank you Jordan!",
                                    subText: "-Arianna Medina"
                                },
                                {type: 'video', url: "https://vimeo.com/user17087177/t7"},
                                {
                                    type: 'text',
                                    text: "Nothing comes even close to this program, best thing since slice bread,period!!!!",
                                    subText: "-Neeraj Kumar"
                                },
                                {type: 'video', url: "https://vimeo.com/user17087177/t6"},
                                {
                                    type: 'text',
                                    text: "My closing percentage has increased to 40% over the past 3 weeks. Thank you JB!",
                                    subText: "-Daniel Orr"
                                },
                                {type: 'video', url: "https://vimeo.com/user17087177/t8"},
                                {
                                    type: 'text',
                                    text: "This is really cool, right? It really is like a facebook for like-minded KILLAS!! It's pretty cool to see how similar all different industries are when it comes down to SELLING & what us salespeople go through on a daily basis.",
                                    subText: "-Justin Brain"
                                },
                                {type: 'video', url: "https://vimeo.com/user17087177/t9"},
                                {
                                    type: 'text',
                                    text: "It's changed my life! It started it retail sales, I went from $800 to 2k by the afternoon after implementing what you've taught. Now I'm in phone sales and thanks to your teachings, I'm second on the leaderboard! (Only reason I'm not first is because I'm not putting as much time as the first guy) lol. Thank you Jordan!",
                                    subText: "-Arianna Medina"
                                },
                            ]}
                        />
                        <button className={classes.button + " " + classes.mobile} onClick={() => this.props.buttonClicked()}>
                            <h3><FaLockOpen/>{buttonTitle}</h3>
                            <p>Claim Your Free Bonuses + A One-Time 70% Discount On Every Product In Our Online Store</p>
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}

export default Leo;
