import React, {Component} from 'react';
import './App.css';
import {connect} from 'react-redux';
import Home from "./components/Landing/Landing";
import * as actions from "./store/actions";
import {Route, Switch} from "react-router-dom";
import Analytics from "./components/Analytics/Analytics";
import Async from "./hoc/AsyncComponent";
import ContactFormPage from "./components/ContactFormPage/ContactFormPage";
import Upsell from "./components/Upsell/Upsell";
import Slp from "./components/Slp/Slp";
import Manual from "./components/Manual/Manual";
import Bundles from "./components/Bundles/Bundles";
import Footer from "./components/Footer/Footer";
import DirectSlp from "./components/Slp/DirectSlp";
import OrderForm from "./components/OrderForm/OrderForm";
import Downsell from "./components/Upsell/Downsell";


class App extends Component {

    componentDidMount() {
        this.props.initSplit();
        this.props.loadState();
    }

    render() {
        let component = null;
        if (this.props.stateLoaded) {
            component = Home;
        }
        if (this.props.homeDone) {
            component = ContactFormPage;
        }
        if (this.props.creditDone) {
            component = Upsell;
        }
        if (this.props.upsellDone) {
            component = Downsell;
        }
        if (this.props.downsellDone) {
            component = Slp;
        }
        if (this.props.slpDone) {
            component = Manual;
        }
        if (this.props.manualDone) {
            component = Async(() => {
                return import("./components/ThankYou/ThankYou");
            });
        }


        return (
            <div className="App">
                <Switch>
                    <Route path="/analytics" component={Analytics}/>
                    <Route path="/bundles" component={Bundles}/>
                    <Route path="/order/:id" component={OrderForm}/>
                    <Route path="/uktour" component={DirectSlp}/>
                    <Route path="/" component={component}/>
                </Switch>
                <Footer/>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        homeDone: state.pages.homeDone,
        creditDone: state.pages.creditDone,
        upsellDone: state.pages.upsellDone,
        downsellDone: state.pages.downsellDone,
        slpDone: state.pages.slpDone,
        manualDone: state.pages.manualDone,
        stateLoaded: state.pages.loaded
    };
};

const mapDispatchToProps = dispatch => {
    return {
        loadState: () => dispatch(actions.loadState()),
        initSplit: () => dispatch(actions.initSplitTests())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
